import AdjustIcon from '@mui/icons-material/Adjust';
import BarChartIcon from '@mui/icons-material/BarChart';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import BuildIcon from '@mui/icons-material/Build';
import ChatIcon from '@mui/icons-material/Chat';
import Crop54Icon from '@mui/icons-material/Crop54';
import EmailIcon from '@mui/icons-material/Email';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PrintIcon from '@mui/icons-material/Print';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import RefreshIcon from '@mui/icons-material/Refresh';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WifiIcon from '@mui/icons-material/Wifi';
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  IconButton,
  Tooltip,
} from '@mui/material';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import capitalize from 'lodash/capitalize';
import isEqual from 'lodash/isEqual';
import union from 'lodash/union';
import {useEffect, useMemo, useRef} from 'react';

import {useConfiguration} from '../../../hooks/configuration';
import {useGetCommtracNodeBatteryPercentage} from '../../../hooks/get-commtrac-node-battery-percentage';
import {useGetCommtracNodeType} from '../../../hooks/get-commtrac-node-type';
import {useAppSelector} from '../../../hooks/redux';
import usePrevious from '../../../hooks/usePrevious';
import {BroadcastMessage} from '../../../interfaces/BroadcastMessage';
import {
  CommtracNode,
  CommtracNodeListQuery,
  CommtracNodeListResponse,
} from '../../../interfaces/CommtracNode';
import {ExportField} from '../../../interfaces/Export';
import reduxSelectors from '../../../redux/selectors';
import {EmployeeEmergencyResponse} from '../../../utils/commtrac-nodes';
import {
  OpenedEntityMode,
  OpenedEntityType,
} from '../../../utils/connect-view-panel';
import {eventIconsLegacy} from '../../../utils/event-icons';
import AccessControl from '../../common/AccessControl';
import {AutoRefreshSettingsSelect} from '../../common/AutoRefreshSettingsSelect';
import {BatteryIcon} from '../../common/BatteryIcon';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import {Map, MapLatLangCoordinates, MapLayerId} from '../../common/Map';
import {ResizableColumns} from '../../common/ResizableColumns';
import CommtracNodeItemsEditButton from '../../commtrac-nodes/buttons/CommtracNodeItemsEditButton';
import CommtracNodeItemsPurgeButton from '../../commtrac-nodes/buttons/CommtracNodeItemsPurgeButton';
import CommtracNodeBroadcastReportFrequencyButton from '../../commtrac-nodes/CommtracNodeBroadcastReportFrequencyButton';
import CommtracNodeImportExportButton from '../../commtrac-nodes/CommtracNodeImportExportButton';
import CommtracNodeItemAssetAcknowledgeButton from '../../commtrac-nodes/CommtracNodeItemAssetAcknowledgeButton';
import CommtracNodeItemMinerAcknowledgeButton from '../../commtrac-nodes/CommtracNodeItemMinerAcknowledgeButton';
import {ZoneSelect} from '../../selectors/ZoneSelect';

interface Props {
  value?: CommtracNodesReportData;
  fetchedData?: CommtracNodeListResponse;
  loading?: boolean;
  selectedBroadcastMessage?: BroadcastMessage;
  onChange?: (value?: CommtracNodesReportData) => void;
  onOpenHistory?: (
    id: number | string,
    type:
      | 'asset'
      | 'cn'
      | 'wifi'
      | 'wifiLongTerm'
      | 'employee'
      | 'commtracNodeByCn'
      | 'networkDiagnostics'
      | 'alarm'
      | 'alarm_log'
      | 'hazard_ai_detection_log'
      | 'hazard_ai_heatmap'
      | 'amsShortTerm'
      | 'amsLongTerm'
      | 'amsLocation'
      | 'amsEmoduleInstallationHistory'
      | 'amsEmoduleSensorHistory'
      | 'amsEmoduleCalibration'
  ) => void;
  onOpenItem?: (
    id: number,
    entity: OpenedEntityType,
    mode?: OpenedEntityMode
  ) => void;
  onRefresh?: () => void;
  onChangeLocationCoordinates?: (value?: MapLatLangCoordinates) => void;
  onChangeIsShownGroupChat?: (value: boolean) => void;
}

export interface CommtracNodesReportData {
  mapLayers: MapLayerId[];
  mapLevel: number | null;
  params: Partial<CommtracNodeListQuery>;
  selectedIds: number[] | null;
  shownFields: {
    all?: string[];
    miners?: string[];
    assets?: string[];
  };
  grid: {
    pageSize: number;
    page: number;
  };
  exportFields: ExportField[];
}

const DEFAULT_SHOWN_FIELDS_MINERS = [
  'chat',
  'battery',
  'alarm',
  'no_location',
  'motion',
  'new_messages',
  'msg_conf',
  'emergency_response',
  'id',
  'type',
  'type_2',
  'commtrac_external_id',
  'name',
  'h_first_name',
  'h_last_name',
  'h_personal_id',
  'h_type_id',
  'commtrac_current_zone_id',
  'commtrac_strongest_cn_name',
  'commtrac_date',
  'commtrac_on_surface',
];

const DEFAULT_SHOWN_FIELDS_ASSETS = [
  'battery',
  'no_location',
  'id',
  'type',
  'type_2',
  'commtrac_external_id',
  'name',
  'm_type_id',
  'commtrac_current_zone_id',
  'commtrac_strongest_cn_name',
  'commtrac_date',
  'commtrac_on_surface',
];

const DEFAULT_SHOWN_FIELDS = union(
  DEFAULT_SHOWN_FIELDS_MINERS,
  DEFAULT_SHOWN_FIELDS_ASSETS
);

export const getCommtracNodesReportData = (): CommtracNodesReportData => {
  return {
    mapLayers: ['employees', 'assets', 'street', 'mine'],
    mapLevel: null,
    params: {
      miner_status: 'checked_in',
      asset_status: 'checked_in',
      node_type: [],
      section_ids: [],
      limit: 2000,
      page: 0,
    },
    selectedIds: null,
    shownFields: {
      all: DEFAULT_SHOWN_FIELDS,
      miners: DEFAULT_SHOWN_FIELDS_MINERS,
      assets: DEFAULT_SHOWN_FIELDS_ASSETS,
    },
    grid: {
      page: 0,
      pageSize: 25,
    },
    exportFields: [],
  };
};

const CommtracNodesReport = ({
  value,
  fetchedData,
  loading,
  selectedBroadcastMessage,
  onChange,
  onOpenItem,
  onOpenHistory,
  onRefresh,
  onChangeLocationCoordinates,
  onChangeIsShownGroupChat,
}: Props) => {
  const config = useMemo(() => value ?? getCommtracNodesReportData(), [value]);

  const isAssetsOnly = useMemo(
    () => isEqual(config.params.node_type, ['asset']),
    [config.params.node_type]
  );

  const isEmployeesOnly = useMemo(
    () => isEqual(config.params.node_type, ['miner']),
    [config.params.node_type]
  );

  /*************/
  /* data grid */
  /*************/
  const myPermissions = useAppSelector(({app}) => app.me?.permissions);
  const assetHumanTypes = useAppSelector(
    ({assets}) => assets.asset_human_types
  );
  const machineTypes = useAppSelector(({assets}) => assets.machine_types);
  const zones = useAppSelector(({assets}) => assets.zones);
  const shifts = useAppSelector(({assets}) => assets.shifts);
  const getCommtracNodeType = useGetCommtracNodeType();
  const getCommtracNodeBatteryPercentage =
    useGetCommtracNodeBatteryPercentage();
  const minerAddressMask = useAppSelector(
    ({assets}) => assets.constants?.miner.address_mask
  );
  const isCompactMode = useAppSelector(reduxSelectors.app.getIsCompactMode);
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = fetchedData?.items ?? [];

  const columns: DataGridColumn<CommtracNode>[] = [
    {
      field: 'select',
      type: 'select',
      doNotExport: true,
      renderHeader: () => (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Checkbox
            color="primary"
            disabled={rows.length === 0}
            checked={selectedItems.length > 0 && selectedAll}
            indeterminate={selectedItems.length > 0 && !selectedAll}
            onChange={() => toggleSelectAllItems()}
          />
        </Box>
      ),
      renderCell: ({row}) => (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Checkbox
            color="primary"
            checked={selectedItems.includes(row.id)}
            onChange={() => toggleSelectItem(row.id)}
            size="small"
          />
        </Box>
      ),
    },
    {
      field: 'actions',
      renderHeader: () => (
        <Tooltip title="Edit">
          <MoreHorizIcon />
        </Tooltip>
      ),
      type: 'actions',
      doNotExport: true,
      sxHeader: {textAlign: 'center', p: 0.5},
      sxCell: {textAlign: 'center', p: 0.5},
      renderCell: ({row}) => {
        if (row.type === 'miner') {
          if (row.commtrac_ack === '0' || !row.employee_asset_id) {
            return (
              <AccessControl
                permissions={['patch::/commtrac-node/:id/miner/acknowledge']}
              >
                <CommtracNodeItemMinerAcknowledgeButton
                  item={row}
                  component={IconButton}
                  componentProps={{
                    color: 'warning',
                  }}
                  onSubmitted={(item) => {
                    onRefresh?.();
                    if (item.employee_asset_id) {
                      if (item.type === 'miner') {
                        onOpenItem?.(item.employee_asset_id, 'employee');
                      } else if (item.type === 'asset') {
                        onOpenItem?.(item.employee_asset_id, 'asset');
                      }
                    }
                  }}
                >
                  <WarningAmberIcon />
                </CommtracNodeItemMinerAcknowledgeButton>
              </AccessControl>
            );
          } else if (row.employee_asset_id) {
            return (
              <CommtracNodeItemsEditButton
                component={IconButton}
                componentProps={{
                  color: 'primary',
                  size: 'small',
                }}
                item={row}
                onOpenItem={onOpenItem}
                onOpenHistory={onOpenHistory}
              >
                <MoreHorizIcon />
              </CommtracNodeItemsEditButton>
            );
          }
        } else if (row.type === 'asset') {
          if (row.commtrac_ack === '0' || !row.employee_asset_id) {
            return (
              <AccessControl
                permissions={['patch::/commtrac-node/:id/asset/acknowledge']}
              >
                <CommtracNodeItemAssetAcknowledgeButton
                  item={row}
                  component={IconButton}
                  componentProps={{
                    color: 'warning',
                    size: 'small',
                  }}
                  onSubmitted={(item) => {
                    onRefresh?.();
                    if (item.employee_asset_id) {
                      onOpenItem?.(item.employee_asset_id, 'asset');
                    }
                  }}
                >
                  <WarningAmberIcon />
                </CommtracNodeItemAssetAcknowledgeButton>
              </AccessControl>
            );
          } else if (row.employee_asset_id) {
            return (
              <CommtracNodeItemsEditButton
                component={IconButton}
                componentProps={{
                  color: 'primary',
                  size: 'small',
                }}
                item={row}
                onOpenItem={onOpenItem}
                onOpenHistory={onOpenHistory}
              >
                <MoreHorizIcon />
              </CommtracNodeItemsEditButton>
            );
          }
        }
      },
    },
    {
      field: 'chat',
      hidden:
        !myPermissions?.includes('get::/commtrac-node/:id/message') ||
        isAssetsOnly,
      headerName: 'Chat',
      doNotExport: true,
      type: 'actions',
      sxHeader: {textAlign: 'center', p: 0.5},
      sxCell: {textAlign: 'center', p: 0.5},
      renderCell: ({row}) => {
        if (row.type === 'miner' && !row.wifi_enabled) {
          return (
            <IconButton
              color="primary"
              disabled={!row.employee_asset_id}
              onClick={() => {
                if (row.employee_asset_id) {
                  onOpenItem?.(row.employee_asset_id, 'employee', 'chat');
                }
              }}
              size="small"
            >
              <ChatIcon />
            </IconButton>
          );
        }
      },
    },
    {
      field: 'battery',
      headerName: 'Battery',
      sxHeader: {minWidth: 60},
      renderHeader: () => (
        <Tooltip title="Low Battery">
          <BatteryChargingFullIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      valueGetter: ({row}) => getCommtracNodeBatteryPercentage(row),
      renderCell: ({value}) => {
        if (typeof value === 'number') {
          return (
            <Tooltip title={`${value}%`}>
              <Box sx={{position: 'relative', left: 4, top: 4}}>
                <BatteryIcon value={value} />
              </Box>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title="Unknown">
              <Box sx={{position: 'relative', left: 4, top: 4}}>
                <QuestionMarkIcon />
              </Box>
            </Tooltip>
          );
        }
      },
    },
    {
      field: 'alarm',
      headerName: 'Alarm',
      hidden: isAssetsOnly,
      sxHeader: {minWidth: 60},
      renderHeader: () => (
        <Tooltip title="Alarm">
          <ReportProblemIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      valueGetter: ({row}) => !!row.e_201,
      renderCell: ({row}) => {
        if (row.type === 'miner') {
          if (row.e_201) {
            return (
              <Tooltip title="Employee Alarm">
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  {eventIconsLegacy.commtracNode.alarm}
                </Box>
              </Tooltip>
            );
          }
        }
      },
    },
    {
      field: 'no_location',
      headerName: 'No Location',
      sxHeader: {minWidth: 60},
      renderHeader: () => (
        <Tooltip title="No Location">
          <WifiIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      valueGetter: ({row}) => {
        if (row.e_202 || row.e_301) {
          return 'no';
        } else if (row.e_221 || row.e_321) {
          return 'many';
        } else if (row.e_226 || row.e_326) {
          return 'invalid';
        }
      },
      renderCell: ({row}) => {
        if (row.type === 'miner') {
          if (row.e_221) {
            return (
              <Tooltip title="Many Location">
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  {eventIconsLegacy.commtracNode.manyLocation}
                </Box>
              </Tooltip>
            );
          } else if (row.e_202) {
            return (
              <Tooltip title="No Location">
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  {eventIconsLegacy.commtracNode.noLocation}
                </Box>
              </Tooltip>
            );
          } else if (row.e_226) {
            return (
              <Tooltip title="Location Derived">
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  {eventIconsLegacy.commtracNode.derivedLocation}
                </Box>
              </Tooltip>
            );
          }
        } else if (row.type === 'asset') {
          if (row.e_321) {
            return (
              <Tooltip title="Many Location">
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  {eventIconsLegacy.commtracNode.manyLocation}
                </Box>
              </Tooltip>
            );
          } else if (row.e_301) {
            return (
              <Tooltip title="No Location">
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  {eventIconsLegacy.commtracNode.noLocation}
                </Box>
              </Tooltip>
            );
          } else if (row.e_326) {
            return (
              <Tooltip title="Location Derived">
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  {eventIconsLegacy.commtracNode.derivedLocation}
                </Box>
              </Tooltip>
            );
          }
        }
      },
    },
    {
      field: 'motion',
      headerName: 'Motion',
      hidden: isAssetsOnly,
      sxHeader: {minWidth: 60},
      renderHeader: () => (
        <Tooltip title="Motion">
          <PersonRemoveIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      valueGetter: ({row}) => (row.e_233 ? row.motion_sensor_value : -1),
      renderCell: ({row, value}) => {
        if (row.type === 'miner') {
          if (value === 0) {
            return (
              <Tooltip title="Employee is in Not Moving State (Underground)">
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  {eventIconsLegacy.commtracNode.minerNotMoving}
                </Box>
              </Tooltip>
            );
          } else if (value === 1) {
            return (
              <Tooltip title="Employee is in Not Moving State (Surface)">
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  {eventIconsLegacy.commtracNode.minerNotMoving}
                </Box>
              </Tooltip>
            );
          } else if (value === 2) {
            return (
              <Tooltip title="Moving State Ok (Surface)">
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  {eventIconsLegacy.commtracNode.minerOkMoving}
                </Box>
              </Tooltip>
            );
          } else if (value === 3) {
            return (
              <Tooltip title="Moving State Ok (Underground)">
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  {eventIconsLegacy.commtracNode.minerOkMoving}
                </Box>
              </Tooltip>
            );
          }
        }
      },
    },
    {
      field: 'new_messages',
      hidden: isAssetsOnly,
      sxHeader: {minWidth: 60},
      renderHeader: () => (
        <Tooltip title="New Messages">
          <EmailIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      doNotExport: true,
      valueGetter: ({row}) => !!row.e_204,
      renderCell: ({row}) => {
        if (row.type === 'miner') {
          if (row.e_204) {
            return (
              <Tooltip title="New Message">
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  <EmailIcon color="success" />
                </Box>
              </Tooltip>
            );
          }
        }
      },
    },
    {
      field: 'msg_conf',
      hidden: isAssetsOnly,
      headerName: 'Msg Conf',
      sxHeader: {minWidth: 60},
      renderHeader: () => (
        <Tooltip title="Msg Conf">
          <BuildIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      valueGetter: ({row}) => !!row.e_222,
      renderCell: ({row}) => {
        if (row.type === 'miner') {
          if (row.e_222) {
            return (
              <Tooltip title="Configuration Message Failed">
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  {eventIconsLegacy.commtracNode.minerMsgConfFailed}
                </Box>
              </Tooltip>
            );
          } else if (row.configuration_message?.answer === 'NACK') {
            return (
              <Tooltip title="NACK">
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  {eventIconsLegacy.commtracNode.minerNack}
                </Box>
              </Tooltip>
            );
          } else if (row.e_232) {
            return (
              <Tooltip title="ACK">
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  {eventIconsLegacy.commtracNode.minerAck}
                </Box>
              </Tooltip>
            );
          }
        }
      },
    },
    {
      field: 'emergency_response',
      hidden: isAssetsOnly,
      headerName: 'Emergency Response',
      sxHeader: {minWidth: 60},
      renderHeader: () => (
        <Tooltip title="Emergency Response">
          <AdjustIcon color="error" />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      doNotExport: true,
      renderCell: ({row}) => {
        if (row.type === 'miner' && selectedBroadcastMessage) {
          const message = row.broadcast_message?.find(
            (i) => i.message_id === selectedBroadcastMessage?.message_id
          );
          if (message?.answer === 'NACK') {
            return (
              <Tooltip title="Not responded">
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  {eventIconsLegacy.commtracNode.minerAnswerNack}
                </Box>
              </Tooltip>
            );
          } else if (message?.answer === 'ANS') {
            if (message.broadcast_status === '1') {
              return (
                <Tooltip title={EmployeeEmergencyResponse.OK}>
                  <Box sx={{position: 'relative', left: 4, top: 4}}>
                    {eventIconsLegacy.commtracNode.employeeEmergencyResponseOk}
                  </Box>
                </Tooltip>
              );
            } else if (message.broadcast_status === '2') {
              return (
                <Tooltip title={EmployeeEmergencyResponse.INJURED_BUT_OK}>
                  <Box sx={{position: 'relative', left: 4, top: 4}}>
                    {
                      eventIconsLegacy.commtracNode
                        .employeeEmergencyResponseInjuredButOk
                    }
                  </Box>
                </Tooltip>
              );
            } else if (message.broadcast_status === '3') {
              return (
                <Tooltip
                  title={EmployeeEmergencyResponse.INJURED_AND_NEEDS_HELP}
                >
                  <Box sx={{position: 'relative', left: 4, top: 4}}>
                    {
                      eventIconsLegacy.commtracNode
                        .employeeEmergencyResponseInjuredAndNeedsHelp
                    }
                  </Box>
                </Tooltip>
              );
            }
            return (
              <Tooltip title="ACK">
                {eventIconsLegacy.commtracNode.minerAnswerAck}
              </Tooltip>
            );
          }
        }
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.type === 'miner') {
          return row.h_nickname;
        } else if (row.type === 'asset') {
          return row.name;
        }
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      sortable: true,
      valueGetter: ({row}) =>
        capitalize(row.type === 'miner' ? 'Employee' : row.type),
    },
    {
      field: 'type_2',
      headerName: 'Type 2',
      sortable: true,
      valueGetter: ({row}) => getCommtracNodeType(row),
    },
    {
      hidden: isAssetsOnly,
      field: 'h_personal_id',
      headerName: 'Employee ID',
      sortable: true,
      valueGetter: ({row}) => row.h_personal_id ?? null,
    },
    {
      field: 'commtrac_external_id',
      headerName: 'Network ID',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.wifi_enabled) {
          return row.mac_address;
        } else if (row.type === 'miner' && row.commtrac_external_id) {
          return minerAddressMask // eslint-disable-next-line
            ? // eslint-disable-next-line no-bitwise
              row.commtrac_external_id & minerAddressMask
            : null;
        } else if (row.type === 'asset') {
          return row.commtrac_external_id;
        }
      },
    },
    {
      field: 'external_id',
      headerName: 'Proximity ID',
      sortable: true,
    },
    {
      hidden: isAssetsOnly,
      field: 'h_first_name',
      headerName: 'First Name',
      sortable: true,
      valueGetter: ({row}) => row.h_first_name,
    },
    {
      hidden: isAssetsOnly,
      field: 'h_last_name',
      headerName: 'Last Name',
      sortable: true,
      valueGetter: ({row}) => row.h_last_name,
    },
    {
      hidden: isAssetsOnly,
      field: 'h_type_id',
      headerName: 'Job Type',
      sortable: true,
      valueGetter: ({row}) =>
        assetHumanTypes.find((i) => row.h_type_id === i.id)?.name,
    },
    {
      hidden: isEmployeesOnly,
      field: 'm_type_id',
      headerName: 'Machine Type',
      sortable: true,
      valueGetter: ({row}) =>
        machineTypes.find((i) => row.m_type_id === i.id)?.name,
    },
    {
      field: 'commtrac_current_zone_id',
      headerName: 'Section',
      sortable: true,
      valueGetter: ({row}) =>
        zones.find((i) => i.id === row.commtrac_current_zone_id)?.name,
    },
    {
      field: 'commtrac_strongest_cn_name',
      headerName: 'Strongest Node',
      sortable: true,
    },
    {
      field: 'commtrac_date',
      headerName: 'Timestamp',
      sortable: true,
    },
    {
      field: 'commtrac_on_surface',
      headerName: 'Pos',
      sortable: true,
      valueGetter: ({row}) => row.commtrac_on_surface === '1',
      renderCell: ({row}) => {
        if (row.commtrac_on_surface === '1') {
          return (
            <Tooltip title="Surface">
              <Box sx={{position: 'relative', left: 4, top: 4}}>
                <BarChartIcon color="success" />
              </Box>
            </Tooltip>
          );
        } else if (row.commtrac_on_surface === '0') {
          return (
            <Tooltip title="Underground">
              <Box sx={{position: 'relative', left: 4, top: 4}}>
                <Crop54Icon color="success" />
              </Box>
            </Tooltip>
          );
        }
      },
    },
    {
      field: 'commtrac_pos_lon',
      headerName: 'Longitude',
      sortable: true,
    },
    {
      field: 'commtrac_pos_lat',
      headerName: 'Latitude',
      sortable: true,
    },
    {
      field: 'commtrac_report_frequency',
      headerName: 'Report Frequency',
      sortable: true,
    },
    {
      field: 'a_type',
      hidden: isAssetsOnly,
      headerName: 'Sensor 1',
      sortable: true,
    },
    {
      field: 'a_value',
      hidden: isAssetsOnly,
      headerName: 'Value 1',
      sortable: true,
    },
    {
      field: 'a_type2',
      hidden: isAssetsOnly,
      headerName: 'Sensor 2',
      sortable: true,
    },
    {
      field: 'a_value2',
      hidden: isAssetsOnly,
      headerName: 'Value 2',
      sortable: true,
    },
    {
      field: 'a_type3',
      hidden: isAssetsOnly,
      headerName: 'Sensor 3',
      sortable: true,
    },
    {
      field: 'a_value3',
      hidden: isAssetsOnly,
      headerName: 'Value 3',
      sortable: true,
    },
    {
      field: 'a_type4',
      hidden: isAssetsOnly,
      headerName: 'Sensor 4',
      sortable: true,
    },
    {
      field: 'a_value4',
      hidden: isAssetsOnly,
      headerName: 'Value 4',
      sortable: true,
    },
    {
      field: 'h_birth_date',
      hidden: isAssetsOnly,
      headerName: 'Birthday',
      sortable: true,
      valueGetter: ({row}) =>
        row.h_birth_date
          ? dayjs.utc(row.h_birth_date).format('YYYY-MM-DD')
          : '',
    },
    {
      field: 'h_shift_id',
      hidden: isAssetsOnly,
      headerName: 'Shift',
      sortable: true,
      valueGetter: ({row}) =>
        shifts?.find((shift) => shift.id === row.h_shift_id)?.name,
    },
    {field: 'commtrac_beacon_data', headerName: 'Beacon Data', sortable: true},
    {
      field: 'date_activated',
      headerName: 'Activated at',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.type === 'miner') {
          return row.h_date_activated;
        } else if (row.type === 'asset') {
          return row.m_date_activated;
        }
      },
    },
    {
      field: 'date_deactivated',
      headerName: 'Deactivated at',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.type === 'miner') {
          return row.h_date_deactivated;
        } else if (row.type === 'asset') {
          return row.m_date_deactivated;
        }
      },
    },
  ];

  const shownFields = useMemo(() => {
    if (isEmployeesOnly) {
      return config.shownFields.miners;
    } else if (isAssetsOnly) {
      return config.shownFields.assets;
    }
    return config.shownFields.all;
  }, [config]);

  useEffect(() => {
    onChange?.(
      update(config, {
        exportFields: {
          $set: columns
            .filter((col) => !col.doNotExport)
            .map((col) => ({
              field: col.field,
              label: col.headerName,
              hidden: shownFields?.indexOf(col.field) === -1,
            })),
        },
      })
    );
  }, [shownFields]);

  const handleChangeShownFields = (fields: string[]) => {
    if (isEmployeesOnly) {
      onChange?.(
        update(config, {
          shownFields: {
            miners: {$set: fields},
          },
        })
      );
    } else if (isAssetsOnly) {
      onChange?.(
        update(config, {
          shownFields: {
            assets: {$set: fields},
          },
        })
      );
    } else {
      onChange?.(
        update(config, {
          shownFields: {
            all: {$set: fields},
          },
        })
      );
    }
  };

  /*******************/
  /* multiple select */
  /*******************/
  const selectedItems = config.selectedIds ?? [];

  const selectedRows = useMemo(
    () => rows.filter((i) => config.selectedIds?.includes(i.id)),
    [rows, config.selectedIds]
  );

  const selectedAll = useMemo(
    () => rows.length === selectedRows.length,
    [rows, selectedRows]
  );

  const toggleSelectItem = (id: number) => {
    if (config.selectedIds?.includes(id)) {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: config.selectedIds.filter((i) => i !== id),
          },
        })
      );
    } else {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: [...(config.selectedIds ?? []), id],
          },
        })
      );
    }
  };

  const selectAll = () => {
    onChange?.(
      update(config, {
        selectedIds: {
          $set: rows?.map((i) => i.id) ?? [],
        },
      })
    );
  };

  const unselectAll = () => {
    onChange?.(
      update(config, {
        selectedIds: {
          $set: [],
        },
      })
    );
  };

  const toggleSelectAllItems = () => {
    if (selectedItems.length >= rows.length) {
      unselectAll();
    } else {
      selectAll();
    }
  };

  useEffect(() => {
    if (
      config.selectedIds &&
      config.selectedIds.length !== selectedRows.length
    ) {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: selectedRows.map((i) => i.id),
          },
        })
      );
    }
  }, [config.selectedIds, selectedRows]);

  const prevSelectedAll = usePrevious(selectedAll);

  useEffect(() => {
    if (prevSelectedAll && !selectedAll) {
      selectAll();
    }
  }, [rows]);

  /************/
  /* map data */
  /************/
  const mapData = useMemo(() => {
    if (fetchedData?.items && config.selectedIds?.length) {
      return fetchedData?.items.filter((i) =>
        config.selectedIds?.includes(i.id)
      );
    }
    return [];
  }, [fetchedData, config.selectedIds, selectedItems]);

  /********************/
  /* refresh interval */
  /********************/
  const refreshPeriod = useConfiguration(
    'auto-refresh',
    'commtrac_miner_autorefresh_rate'
  );

  return (
    <Box height="100%" width="100%">
      <ResizableColumns
        left={
          <>
            <Box
              display="flex"
              alignItems="center"
              height={isCompactMode ? 56 : 70}
              p={isCompactMode ? 1 : 2}
              minWidth={520}
              gap={2}
            >
              <AccessControl permissions={['get::/group-message']}>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{height: '100%'}}
                  startIcon={<ChatIcon />}
                  onClick={() => onChangeIsShownGroupChat?.(true)}
                >
                  Group Message
                </Button>
              </AccessControl>
              <AccessControl
                permissions={[
                  'patch::/commtrac-node/miner/broadcast_report_frequency',
                ]}
              >
                <CommtracNodeBroadcastReportFrequencyButton
                  componentProps={{
                    variant: 'outlined',
                    size: 'small',
                    sx: {height: '100%'},
                  }}
                >
                  Broadcast Reporting Frequency
                </CommtracNodeBroadcastReportFrequencyButton>
              </AccessControl>
            </Box>
            <Map
              panel="cn_report"
              commtracNodes={mapData}
              selectedMapLayers={config.mapLayers}
              selectedLevel={config.mapLevel}
              height="calc(100% - 70px)"
              minWidth={200}
              availableMapLayers={[
                'employees',
                'assets',
                'street',
                'mine',
                'nodes',
                'alarms',
              ]}
              onSelectMapLayers={(v) => {
                onChange?.(
                  update(config, {
                    mapLayers: {
                      $set: v,
                    },
                    params: {
                      node_type: {
                        $set: [
                          ...(v.includes('employees') ? ['miner'] : []),
                          ...(v.includes('assets') ? ['asset'] : []),
                        ] as CommtracNodesReportData['params']['node_type'],
                      },
                    },
                  })
                );
              }}
              onSelectLevel={(v) => {
                onChange?.(
                  update(config, {
                    mapLevel: {
                      $set: v,
                    },
                  })
                );
              }}
              onOpenItem={onOpenItem}
              onOpenHistory={onOpenHistory}
              onGetClickCoordinates={onChangeLocationCoordinates}
            />
          </>
        }
      >
        <Box display="flex" flexDirection="column" height="100%">
          <Box p={isCompactMode ? 1 : 2} minWidth={800}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              height={40}
              gap={2}
            >
              <Box flexGrow={1} display="flex" height="100%">
                <ZoneSelect
                  value={config.params.section_ids?.[0]}
                  size="small"
                  nullLabel="All Sections"
                  fullWidth
                  onChange={(v) => {
                    const sectionIds = v ? [+v] : [];
                    onChange?.(
                      update(config, {
                        params: {
                          section_ids: {
                            $set: sectionIds,
                          },
                        },
                      })
                    );
                  }}
                />
              </Box>

              <Box display="flex" height="100%">
                <ButtonGroup>
                  <CommtracNodeImportExportButton
                    value={value}
                    component={Button}
                    componentProps={{color: 'primary'}}
                    onSubmitted={() => onRefresh?.()}
                  >
                    <ImportExportIcon />
                  </CommtracNodeImportExportButton>

                  <Button onClick={() => onRefresh?.()}>
                    <RefreshIcon />
                  </Button>

                  {refreshPeriod ? (
                    <AutoRefreshSettingsSelect refreshPeriod={refreshPeriod} />
                  ) : null}

                  <Button onClick={() => dataGridRef.current?.printTable()}>
                    <PrintIcon />
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>
          </Box>
          <DataGrid
            ref={dataGridRef}
            rows={rows}
            columns={columns}
            size="small"
            pagination
            page={config.grid.page}
            pageSize={config.grid.pageSize}
            loading={loading}
            shownFields={shownFields}
            sxFooter={{
              bgcolor: (theme) =>
                theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
            }}
            footerStart={
              selectedItems.length ? (
                <Box display="flex" alignItems="center" gap={3} px={1}>
                  <Box
                    display="flex"
                    gap={0.5}
                    alignItems="center"
                    height="100%"
                    whiteSpace="nowrap"
                  >
                    {selectedItems.length} selected
                  </Box>
                  <AccessControl permissions={['post::/purge']}>
                    <Box display="flex" height={40}>
                      <CommtracNodeItemsPurgeButton
                        ids={selectedRows.map((i) => i.id)}
                        purgeChatEnabled={
                          selectedRows.filter((i) => i.type === 'miner')
                            .length > 0
                        }
                        componentProps={{size: 'small', variant: 'outlined'}}
                        onDone={onRefresh}
                      >
                        <RemoveCircleOutlineIcon
                          fontSize="small"
                          sx={{mr: 1}}
                        />{' '}
                        Purge
                      </CommtracNodeItemsPurgeButton>
                    </Box>
                  </AccessControl>
                </Box>
              ) : null
            }
            onShownFieldsChange={handleChangeShownFields}
            onPageChange={(v) => {
              onChange?.(
                update(config, {
                  grid: {
                    page: {
                      $set: v,
                    },
                  },
                })
              );
            }}
            onPageSizeChange={(v) => {
              onChange?.(
                update(config, {
                  grid: {
                    pageSize: {
                      $set: v,
                    },
                  },
                })
              );
            }}
          />
        </Box>
      </ResizableColumns>
    </Box>
  );
};

export default CommtracNodesReport;
