/* eslint-disable complexity */
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tab,
  TextField,
} from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {useEffect, useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useGetAssetHumanType} from '../../hooks/get-commtrac-node-type';
import {useGetMinerNetworkId} from '../../hooks/get-miner-network-id';
import {useAppSelector} from '../../hooks/redux';
import {AssetHuman} from '../../interfaces/AssetHuman';
import {getBooleanValue} from '../../utils/boolean';
import {
  getIsCommtracExternalIdVisible,
  // getIsEmployeeAtestVisible,
  getIsEmployeeHasMotionSensorVisible,
  getIsEmployeeMc2FlagVisible,
  // getIsEmployeeSensorNumberVisible,
  getIsMacAddressVisible,
  getIsMaxVoltageVisible,
  getIsMinVoltageVisible,
  getIsWifiEnabledVisible,
} from '../../utils/commtrac-nodes';
import AccessControl from '../common/AccessControl';
import CommtracNodeChat from '../commtrac-nodes/CommtracNodeChat';
import CommtracNodeItemMinerReportFrequencyUpdateButton from '../commtrac-nodes/CommtracNodeItemMinerReportFrequencyUpdateButton';
import {AssetHumanTypeSelect} from '../selectors/AssetHumanTypeSelect';
import {ShiftSelect} from '../selectors/ShiftSelect';
import StatusSelect from '../selectors/StatusSelect';
import {ZoneSelect} from '../selectors/ZoneSelect';
import AssetHumanItemActionsButton from './AssetHumanItemActionsButton';
import AssetHumanItemUpdateNetworkButton from './AssetHumanItemUpdateNetworkButton';

interface Props {
  pk: number;
  item?: AssetHuman;
  prefetch?: boolean;
  tab?: TabValue;
  onCancel?: () => void;
  onEdit?: () => void;
  onAck?: () => void;
  onPurged?: () => void;
  onFetched?: (item: AssetHuman) => void;
  onSubmitted?: () => void;
  onOpenHistory?: (item: AssetHuman) => void;
}

type TabValue = 'general' | 'location' | 'connection' | 'sensors' | 'chat';
interface TabItem {
  value: TabValue;
  label: string;
  visible: boolean;
}

const AssetHumanItemView = ({
  pk,
  item,
  prefetch,
  tab: initialTab = 'general',
  onCancel,
  onEdit,
  onAck,
  onPurged,
  onFetched,
  onOpenHistory,
  onSubmitted,
}: Props) => {
  const getMinerNetworkId = useGetMinerNetworkId();
  const myPermissions = useAppSelector(({app}) => app.me?.permissions);

  const tabs: TabItem[] = [
    {
      value: 'general',
      label: 'General',
      visible: true,
    },
    {
      value: 'location',
      label: 'Location',
      visible: true,
    },
    {
      value: 'connection',
      label: 'Connection',
      visible: true,
    },
    // {
    //   value: 'sensors',
    //   label: 'Sensors',
    //   visible: true,
    // },
    {
      value: 'chat',
      label: 'Chat',
      visible: !!myPermissions?.includes('get::/commtrac-node/:id/message'),
    },
  ];

  const visibleTabs = tabs.filter((i) => i.visible);

  const [activeTab, setActiveTab] = useState(initialTab);

  /*********/
  /* fetch */
  /*********/

  const [fetchedData, setFetchedData] = useState<AssetHuman | undefined>(
    cloneDeep(item)
  );
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const company = useAppSelector(({assets}) => assets.company);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<AssetHuman>(`${apiBaseUrl}/asset-human/${pk}`);
      setFetchedData(resp.data);
      onFetched?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (!isEqual(item, fetchedData)) {
      setFetchedData(item);
    }
  }, [item]);

  useEffect(() => {
    if (initialTab !== activeTab) {
      setActiveTab(initialTab);
    }
  }, [initialTab]);

  const isWifiEnabledVisible = getIsWifiEnabledVisible(true);
  const isCommtracExternalIdVisible = getIsCommtracExternalIdVisible(
    true,
    !!fetchedData?.wifi_enabled
  );
  const isEmployeeMc2FlagVisible = getIsEmployeeMc2FlagVisible(
    true,
    !!fetchedData?.wifi_enabled
  );
  const isEmployeeHasMotionSensorVisible = getIsEmployeeHasMotionSensorVisible(
    true,
    !!fetchedData?.wifi_enabled
  );
  const isMacAddressVisible = getIsMacAddressVisible(
    true,
    !!fetchedData?.wifi_enabled
  );
  const isMinVoltageVisible = getIsMinVoltageVisible(
    true,
    !!fetchedData?.wifi_enabled
  );
  const isMaxVoltageVisible = getIsMaxVoltageVisible(
    true,
    !!fetchedData?.wifi_enabled
  );

  const getAsssetHumanType = useGetAssetHumanType();
  const assetHumanType = getAsssetHumanType({
    commtrac_external_id: fetchedData?.commtrac_external_id,
    wifi_enabled: fetchedData?.wifi_enabled,
    atest: fetchedData?.atest,
    mc2_flag: fetchedData?.mc2_flag,
    sensors_number: fetchedData?.sensors_number,
  });

  return (
    <Box display="flex" flexDirection="column" position="relative" gap={3}>
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}
      <TabContext value={activeTab}>
        <TabList variant="fullWidth">
          {visibleTabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              onClick={() => setActiveTab(tab.value)}
            />
          ))}
        </TabList>
        <TabPanel value="general" sx={{p: 0}}>
          <Box display="flex" flexDirection="column" gap={3}>
            <TextField
              value={fetchedData?.nickname ?? ''}
              label="Nickname"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={fetchedData?.first_name ?? ''}
              label="First Name"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={fetchedData?.last_name ?? ''}
              label="Last Name"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={fetchedData?.personal_id ?? ''}
              label="Employee ID"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={fetchedData?.birth_date ?? ''}
              label="Date of Birth"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={fetchedData?.description ?? ''}
              label="Description"
              size="small"
              disabled
              fullWidth
              multiline
              rows={3}
            />

            <TextField
              value={assetHumanType ?? ''}
              label="Type"
              size="small"
              disabled
              fullWidth
            />

            <AssetHumanTypeSelect
              value={fetchedData?.type_id}
              label="Job Type"
              size="small"
              disabled
              fullWidth
            />

            <ZoneSelect
              value={fetchedData?.zone_id}
              label="Assigned Section"
              size="small"
              disabled
              fullWidth
            />

            <ShiftSelect
              value={fetchedData?.shift_id}
              label="Assigned Shift"
              size="small"
              disabled
              fullWidth
            />

            <StatusSelect
              value={fetchedData?.status}
              fullWidth
              label="Status"
              size="small"
              disabled
            />

            {company?.commtrac_enabled ? (
              <TextField
                value={fetchedData?.commtrac_date ?? ''}
                label="Last Seen"
                size="small"
                disabled
                fullWidth
              />
            ) : null}

            <TextField
              value={fetchedData?.date_activated ?? ''}
              label="Activated TimeStamp"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={fetchedData?.date_deactivated ?? ''}
              label="DeActivated TimeStamp"
              size="small"
              disabled
              fullWidth
            />
          </Box>
        </TabPanel>
        <TabPanel value="location" sx={{p: 0}}>
          <Box display="flex" flexDirection="column" gap={3}>
            <ZoneSelect
              value={fetchedData?.commtrac_current_zone_id}
              label="Section"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={fetchedData?.commtrac_pos_lat ?? ''}
              label="Latitude"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={fetchedData?.commtrac_pos_lon ?? ''}
              label="Longitude"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={fetchedData?.commtrac_strongest_cn_name ?? ''}
              label="Strongest Node"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={fetchedData?.commtrac_date ?? ''}
              label="Timestamp"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={getBooleanValue(
                fetchedData?.commtrac_on_surface,
                'Underground',
                'On Surface'
              )}
              label="Pos"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={fetchedData?.commtrac_beacon_data ?? ''}
              label="Beacon Data"
              size="small"
              disabled
              fullWidth
            />
          </Box>
        </TabPanel>
        <TabPanel value="connection" sx={{p: 0}}>
          <Box display="flex" flexDirection="column" gap={3}>
            {isWifiEnabledVisible ? (
              <TextField
                value={getBooleanValue(fetchedData?.wifi_enabled ?? false)}
                label="WiFi Enabled"
                size="small"
                disabled
                fullWidth
              />
            ) : null}

            {isCommtracExternalIdVisible ? (
              <TextField
                value={
                  getMinerNetworkId(fetchedData?.commtrac_external_id) ?? ''
                }
                label="Network ID"
                size="small"
                disabled
                fullWidth
                InputProps={{
                  endAdornment: (
                    <AccessControl
                      permissions={[
                        'patch::/asset-human/:id/update_network_id',
                      ]}
                    >
                      {fetchedData ? (
                        <AssetHumanItemUpdateNetworkButton
                          pk={fetchedData.id}
                          item={fetchedData}
                          component={IconButton}
                          componentProps={{color: 'primary', size: 'small'}}
                          onSubmitted={onSubmitted}
                        >
                          <EditIcon fontSize="small" />
                        </AssetHumanItemUpdateNetworkButton>
                      ) : null}
                    </AccessControl>
                  ),
                }}
              />
            ) : null}

            {isMacAddressVisible ? (
              <TextField
                value={fetchedData?.mac_address ?? ''}
                label="Mac Address"
                size="small"
                disabled
                fullWidth
                InputProps={{
                  endAdornment: (
                    <AccessControl
                      permissions={[
                        'patch::/asset-human/:id/update_network_id',
                      ]}
                    >
                      {fetchedData ? (
                        <AssetHumanItemUpdateNetworkButton
                          pk={fetchedData.id}
                          item={fetchedData}
                          component={IconButton}
                          componentProps={{color: 'primary', size: 'small'}}
                          onSubmitted={onSubmitted}
                        >
                          <EditIcon fontSize="small" />
                        </AssetHumanItemUpdateNetworkButton>
                      ) : null}
                    </AccessControl>
                  ),
                }}
              />
            ) : null}

            {isMinVoltageVisible ? (
              <TextField
                value={
                  fetchedData?.min_voltage ? fetchedData?.min_voltage / 10 : ''
                }
                label="Min Voltage"
                size="small"
                disabled
                fullWidth
              />
            ) : null}

            {isMaxVoltageVisible ? (
              <TextField
                value={
                  fetchedData?.max_voltage ? fetchedData?.max_voltage / 10 : ''
                }
                label="Max Voltage"
                size="small"
                disabled
                fullWidth
              />
            ) : null}

            {company?.proximity_enabled ? (
              <TextField
                value={fetchedData?.external_id ?? ''}
                label="Proximity ID"
                size="small"
                disabled
                fullWidth
              />
            ) : null}

            <TextField
              value={fetchedData?.commtrac_report_frequency ?? ''}
              label="Report Frequency (s)"
              size="small"
              disabled
              fullWidth
              InputProps={{
                endAdornment: fetchedData?.commtrac_node_id ? (
                  <AccessControl
                    permissions={[
                      'patch::/commtrac-node/:id/miner/report_frequency',
                    ]}
                  >
                    <CommtracNodeItemMinerReportFrequencyUpdateButton
                      pk={fetchedData?.commtrac_node_id}
                      item={{
                        commtrac_report_frequency:
                          fetchedData?.commtrac_report_frequency,
                      }}
                      component={IconButton}
                      componentProps={{color: 'primary', size: 'small'}}
                    >
                      <EditIcon fontSize="small" />
                    </CommtracNodeItemMinerReportFrequencyUpdateButton>
                  </AccessControl>
                ) : null,
              }}
            />

            {isEmployeeMc2FlagVisible ? (
              <TextField
                value={getBooleanValue(fetchedData?.mc2_flag)}
                label="MC2"
                size="small"
                disabled
                fullWidth
              />
            ) : null}

            <TextField
              value={
                assetHumanType === 'CAM'
                  ? getBooleanValue(fetchedData?.cam_monitor_battery)
                  : 'Yes'
              }
              label="Monitor Battery"
              size="small"
              disabled
              fullWidth
            />

            {isEmployeeHasMotionSensorVisible ? (
              <TextField
                value={getBooleanValue(fetchedData?.has_motion_sensor)}
                label="Motion Sensor"
                size="small"
                disabled
                fullWidth
              />
            ) : null}
          </Box>
        </TabPanel>

        {/* <TabPanel value="sensors" sx={{p: 0}}>
          <Box display="flex" flexDirection="column" gap={3}>
            {isEmployeeAtestVisible ? (
              <TextField
                value={getBooleanValue(fetchedData?.atest)}
                label="ATest"
                size="small"
                disabled
                fullWidth
              />
            ) : null}

            {isEmployeeSensorNumberVisible ? (
              <TextField
                value={fetchedData?.sensors_number}
                label="Sensors Number"
                size="small"
                disabled
                fullWidth
              />
            ) : null}

            <TextField
              // value={fetchedData?.a_type ?? ''}
              label="Sensor 1"
              size="small"
              fullWidth
              disabled
            />

            <TextField
              // value={fetchedData?.a_value ?? ''}
              label="Value 1"
              size="small"
              fullWidth
              disabled
            />

            <TextField
              // value={fetchedData?.a_type2 ?? ''}
              label="Sensor 2"
              size="small"
              fullWidth
              disabled
            />

            <TextField
              // value={fetchedData?.a_value2 ?? ''}
              label="Value 2"
              size="small"
              fullWidth
              disabled
            />

            <TextField
              // value={fetchedData?.a_type3 ?? ''}
              label="Sensor 3"
              size="small"
              fullWidth
              disabled
            />

            <TextField
              // value={fetchedData?.a_value3 ?? ''}
              label="Value 3"
              size="small"
              fullWidth
              disabled
            />

            <TextField
              // value={fetchedData?.a_type4 ?? ''}
              label="Sensor 4"
              size="small"
              fullWidth
              disabled
            />

            <TextField
              // value={fetchedData?.a_value4 ?? ''}
              label="Value 4"
              size="small"
              fullWidth
              disabled
            />
          </Box>
        </TabPanel> */}
        <TabPanel value="chat" sx={{p: 0}}>
          {fetchedData?.commtrac_node_id ? (
            <CommtracNodeChat pk={fetchedData.commtrac_node_id} />
          ) : fetchedData && !fetchedData?.commtrac_node_id ? (
            <Alert color="warning">
              Chat is disabled for employee without commtrac node
            </Alert>
          ) : null}
        </TabPanel>
      </TabContext>
      {activeTab !== 'chat' ? (
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" justifyContent="start" gap={0.5}>
            {onOpenHistory && fetchedData?.commtrac_node_id ? (
              <Button onClick={() => onOpenHistory(fetchedData)}>
                History
              </Button>
            ) : null}
            {fetchedData ? (
              <>
                {onAck && fetchedData?.commtrac_ack !== '1' ? (
                  <AccessControl
                    permissions={[
                      'patch::/commtrac-node/:id/miner/acknowledge',
                    ]}
                  >
                    <Button onClick={() => onAck()}>Acknowledge</Button>
                  </AccessControl>
                ) : null}
                <AccessControl permissions={['patch::/node/:id/wifi-point']}>
                  <AssetHumanItemActionsButton
                    item={fetchedData}
                    component={IconButton}
                    componentProps={{size: 'small', color: 'primary'}}
                    onSubmitted={() => {
                      fetchData();
                      onSubmitted?.();
                    }}
                    onPurged={onPurged}
                  >
                    <MoreHorizIcon />
                  </AssetHumanItemActionsButton>
                </AccessControl>
              </>
            ) : null}
          </Box>
          <Box display="flex" justifyContent="end" gap={0.5}>
            {onCancel ? (
              <Button onClick={() => onCancel()}>Cancel</Button>
            ) : null}
            {onEdit ? (
              <AccessControl permissions={['patch::/asset-human-type/:id']}>
                <Button onClick={() => onEdit()}>Edit Employee</Button>
              </AccessControl>
            ) : null}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default AssetHumanItemView;
