import AddIcon from '@mui/icons-material/Add';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import PrintIcon from '@mui/icons-material/Print';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {LoadingButton, TabContext, TabList, TabPanel} from '@mui/lab';
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Tab,
  Typography,
} from '@mui/material';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import AdminCompanyEdit, {
  AdminCompanyEditRef,
} from '../../components/admin/AdminCompanyEdit';
import {
  AdminCompanySettingsConfigRef,
  AdminCompanySettingsSystemConfig,
} from '../../components/admin/AdminCompanySettingsSystemConfig';
import {
  EmployeeGroupList,
  EmployeeGroupListRef,
} from '../../components/admin/AdminGroups';
import {AlarmGroupItemUpsertButton} from '../../components/alarm-nodes/AlarmGroupItemUpsertButton';
import AlarmGroupList, {
  AlarmGroupListRef,
} from '../../components/alarm-nodes/AlarmGroupList';
import AssetHumanItemUpsertButton from '../../components/asset-human/AssetHumanItemUpsertButton';
import AssetHumanList, {
  AssetHumanListRef,
} from '../../components/asset-human/AssetHumanList';
import AssetHumanTypeImportExportButton from '../../components/asset-human-type/AssetHumanTypeImportExportButton';
import AssetHumanTypeItemUpsertButton from '../../components/asset-human-type/AssetHumanTypeItemUpsertButton';
import AssetHumanTypeList, {
  AssetHumanTypeListRef,
} from '../../components/asset-human-type/AssetHumanTypeList';
import AssetMachineItemUpsertButton from '../../components/asset-machine/AssetMachineItemUpsertButton';
import AssetMachineList, {
  AssetMachineListRef,
} from '../../components/asset-machine/AssetMachineList';
import AccessControl from '../../components/common/AccessControl';
import CommtracNodeList, {
  CommtracNodeListRef,
} from '../../components/commtrac-nodes/CommtracNodeList';
import {GroupItemUpsertButton} from '../../components/human-groups/buttons/GroupItemUpsertButton';
import {LayerItemUpsertButton} from '../../components/layers/buttons/LayerItemUpsertButton';
import {LayerList, LayerListRef} from '../../components/layers/LayerList';
import MachineInputImportExportButton from '../../components/machine-input/MachineInputImportExportButton';
import MachineInputItemUpsertButton from '../../components/machine-input/MachineInputItemUpsertButton';
import MachineInputList, {
  MachineInputListRef,
} from '../../components/machine-input/MachineInputList';
import MachineTypeImportExportButton from '../../components/machine-type/MachineTypeImportExportButton';
import MachineTypeItemUpsertButton from '../../components/machine-type/MachineTypeItemUpsertButton';
import MachineTypeList, {
  MachineTypeListRef,
} from '../../components/machine-type/MachineTypeList';
import {MapItemUploadButton} from '../../components/maps/buttons/MapItemUploadButton';
import {MapList, MapListRef} from '../../components/maps/MapList';
import ShiftImportExportButton from '../../components/shifts/ShiftImportExportButton';
import ShiftItemUpsertButton from '../../components/shifts/ShiftItemUpsertButton';
import ShiftList, {ShiftListRef} from '../../components/shifts/ShiftList';
import SiteImportExportButton from '../../components/site/SiteImportExportButton';
import SiteItemUpsertButton from '../../components/site/SiteItemUpsertButton';
import SiteList, {SiteListRef} from '../../components/site/SiteList';
import ZoneImportExportButton from '../../components/zone/ZoneImportExportButton';
import ZoneItemUpsertButton from '../../components/zone/ZoneItemUpsertButton';
import ZoneList, {ZoneListRef} from '../../components/zone/ZoneList';
import {UseGetLabel} from '../../hooks/label';
import {useAppSelector} from '../../hooks/redux';
import {DashboardLayout} from '../../layouts/DashboardLayout';
import reduxSelectors from '../../redux/selectors';

interface TabGroup {
  name: 'general' | 'employees' | 'assets_machines' | 'alarms';
  label: string;
  product: (
    | 'connect_enabled'
    | 'proximity_enabled'
    | 'hazard_ai_enabled'
    | 'alarm_enabled'
  )[];
}

const tabGroups: TabGroup[] = [
  {
    name: 'general',
    label: 'General',
    product: [],
  },
  {
    name: 'employees',
    label: 'Employees',
    product: ['proximity_enabled'],
  },
  {
    name: 'assets_machines',
    label: 'Assets/Machines',
    product: [],
  },
  {
    name: 'alarms',
    label: 'Alarms',
    product: ['alarm_enabled'],
  },
];

export const AdminCompanySettingsPage: React.FC = () => {
  const me = useAppSelector(({app}) => app.me);
  const productsEnabled = useAppSelector(reduxSelectors.assets.productsEnabled);
  const company = useAppSelector(({assets}) => assets.company);
  const hazard_ai_enabled = !!company?.hazard_ai_enabled;
  const connect_enabled = !!company?.commtrac_enabled;
  const proximity_enabled = !!company?.proximity_enabled;
  const alarm_enabled = !!company?.alarm_enabled;
  const [activeTabGroup, setActiveTabGroup] = useState<
    'general' | 'employees' | 'assets_machines' | 'alarms' | string
  >('general');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const companyEditRef = useRef<AdminCompanyEditRef | null>(null);
  const zonesRef = useRef<ZoneListRef | null>(null);
  const sitesRef = useRef<SiteListRef | null>(null);
  const shiftsRef = useRef<ShiftListRef | null>(null);
  const machineTypesRef = useRef<MachineTypeListRef | null>(null);
  const assetMachineRef = useRef<AssetMachineListRef | null>(null);
  const assetHumanRef = useRef<AssetHumanListRef | null>(null);
  const machineInputsRef = useRef<MachineInputListRef | null>(null);
  const assetHumanTypeRef = useRef<AssetHumanTypeListRef | null>(null);
  const layersRef = useRef<LayerListRef | null>(null);
  const mapsRef = useRef<MapListRef | null>(null);
  const settingRef = useRef<AdminCompanySettingsConfigRef>(null);
  const employeeGroups = useRef<EmployeeGroupListRef | null>(null);
  const alarmGroups = useRef<AlarmGroupListRef | null>(null);
  const commtracNodeRef = useRef<CommtracNodeListRef | null>(null);

  const tabs = [
    {
      label: 'Company',
      value: 'company',
      enabled: true,
      group: 'general',
      component: (
        <AccessControl permissions={['get::/company/current']}>
          <AdminCompanyEdit
            disabled={!me?.permissions.includes('patch::/company/current')}
            ref={companyEditRef}
          />
        </AccessControl>
      ),
      actions: (
        <>
          <IconButton
            color="primary"
            onClick={() => companyEditRef?.current?.fetch?.()}
          >
            <RestartAltIcon />
          </IconButton>
          <AccessControl permissions={['patch::/company/current']}>
            <LoadingButton
              variant="contained"
              loading={isSubmitting}
              onClick={() => companyEditRef?.current?.submit?.()}
            >
              Save All Changes
            </LoadingButton>
          </AccessControl>
        </>
      ),
      ref: companyEditRef,
    },
    {
      label: 'Sections',
      value: 'sections',
      enabled: connect_enabled || proximity_enabled,
      group: 'general',
      component: (
        <AccessControl permissions={['get::/zone']}>
          <ZoneList ref={zonesRef} />
        </AccessControl>
      ),
      actions: (
        <>
          <IconButton
            color="primary"
            onClick={() => zonesRef?.current?.fetch?.()}
          >
            <RestartAltIcon />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => zonesRef.current?.dataGridRef.current?.printTable()}
          >
            <PrintIcon />
          </IconButton>
          <AccessControl permissions={['post::/zone']}>
            <ZoneItemUpsertButton
              component={Button}
              componentProps={{variant: 'outlined', startIcon: <AddIcon />}}
              onSubmitted={() => zonesRef?.current?.fetch?.()}
            >
              Add Section
            </ZoneItemUpsertButton>
          </AccessControl>
          <ZoneImportExportButton
            component={Button}
            componentProps={{
              variant: 'outlined',
              startIcon: <ImportExportIcon />,
            }}
            onSubmitted={() => zonesRef?.current?.fetch?.()}
          >
            Import/Export
          </ZoneImportExportButton>
        </>
      ),
      ref: zonesRef,
    },
    {
      label: 'Sites',
      value: 'sites',
      group: 'general',
      enabled: productsEnabled.includes('hazard'),
      component: (
        <AccessControl permissions={['get::/site']}>
          <SiteList ref={sitesRef} />
        </AccessControl>
      ),
      actions: (
        <>
          <IconButton
            color="primary"
            onClick={() => sitesRef?.current?.fetch?.()}
          >
            <RestartAltIcon />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => sitesRef.current?.dataGridRef.current?.printTable()}
          >
            <PrintIcon />
          </IconButton>
          <AccessControl permissions={['post::/site']}>
            <SiteItemUpsertButton
              component={Button}
              componentProps={{variant: 'outlined', startIcon: <AddIcon />}}
              onSubmitted={() => sitesRef?.current?.fetch?.()}
            >
              Add Site
            </SiteItemUpsertButton>
          </AccessControl>
          <SiteImportExportButton
            component={Button}
            componentProps={{
              variant: 'outlined',
              startIcon: <ImportExportIcon />,
            }}
            onSubmitted={() => sitesRef?.current?.fetch?.()}
          >
            Import/Export
          </SiteImportExportButton>
        </>
      ),
      ref: sitesRef,
    },
    {
      label: 'System',
      value: 'system',
      group: 'general',
      enabled:
        proximity_enabled ||
        connect_enabled ||
        hazard_ai_enabled ||
        alarm_enabled,
      component: (
        <AccessControl permissions={['get::/configuration']}>
          <AdminCompanySettingsSystemConfig
            ref={settingRef}
            disabled={!me?.permissions.includes('post::/configuration')}
            onChangeSubmittedInProgress={setIsSubmitting}
          />
        </AccessControl>
      ),
      actions: (
        <>
          <IconButton
            color="primary"
            onClick={() => settingRef?.current?.fetch?.()}
          >
            <RestartAltIcon />
          </IconButton>
          <AccessControl permissions={['post::/configuration']}>
            <LoadingButton
              variant="contained"
              loading={isSubmitting}
              onClick={() => settingRef?.current?.submit?.()}
            >
              Save All Changes
            </LoadingButton>
          </AccessControl>
        </>
      ),
      ref: settingRef,
    },
    {
      label: 'Employees',
      value: 'employee',
      enabled: connect_enabled || proximity_enabled,
      component: <AssetHumanList ref={assetHumanRef} />,
      group: 'employees',
      actions: (
        <>
          <IconButton
            color="primary"
            onClick={() => assetHumanRef?.current?.fetch?.()}
          >
            <RestartAltIcon />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() =>
              assetHumanRef.current?.dataGridRef.current?.printTable()
            }
          >
            <PrintIcon />
          </IconButton>
          <AssetHumanItemUpsertButton
            component={Button}
            componentProps={{variant: 'outlined', startIcon: <AddIcon />}}
            onSubmitted={() => assetHumanRef?.current?.fetch?.()}
          >
            Add Employee
          </AssetHumanItemUpsertButton>
        </>
      ),
      ref: assetHumanRef,
    },
    {
      label: 'Employee Types',
      value: 'human-types',
      enabled: proximity_enabled || connect_enabled,
      component: <AssetHumanTypeList ref={assetHumanTypeRef} />,
      group: 'employees',
      actions: (
        <>
          <IconButton
            color="primary"
            onClick={() => assetHumanTypeRef?.current?.fetch?.()}
          >
            <RestartAltIcon />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() =>
              assetHumanTypeRef.current?.dataGridRef.current?.printTable()
            }
          >
            <PrintIcon />
          </IconButton>
          <AssetHumanTypeItemUpsertButton
            component={Button}
            componentProps={{variant: 'outlined', startIcon: <AddIcon />}}
            onSubmitted={() => assetHumanTypeRef?.current?.fetch?.()}
          >
            Add Employee Type
          </AssetHumanTypeItemUpsertButton>
          <AssetHumanTypeImportExportButton
            component={Button}
            componentProps={{
              variant: 'outlined',
              startIcon: <ImportExportIcon />,
            }}
            onSubmitted={() => assetHumanTypeRef?.current?.fetch?.()}
          >
            Export to CSV
          </AssetHumanTypeImportExportButton>
        </>
      ),
      ref: assetHumanTypeRef,
    },
    {
      label: 'Groups',
      value: 'groups',
      enabled: productsEnabled.includes('connect') || connect_enabled,
      group: 'employees',
      component: (
        <AccessControl permissions={['get::/asset-human-group-grid']}>
          <EmployeeGroupList ref={employeeGroups} />
        </AccessControl>
      ),
      actions: (
        <>
          <IconButton
            color="primary"
            onClick={() => employeeGroups?.current?.fetch?.()}
          >
            <RestartAltIcon />
          </IconButton>
          <AccessControl permissions={['post::/asset-human-group']}>
            <GroupItemUpsertButton
              component={Button}
              componentProps={{variant: 'outlined', startIcon: <AddIcon />}}
              onSubmitted={() => employeeGroups?.current?.fetch?.()}
            >
              Add Group
            </GroupItemUpsertButton>
          </AccessControl>
        </>
      ),
      ref: employeeGroups,
    },
    {
      label: 'Shifts',
      value: 'shifts',
      enabled: proximity_enabled || connect_enabled,
      component: <ShiftList ref={shiftsRef} />,
      group: 'employees',
      actions: (
        <>
          <IconButton
            color="primary"
            onClick={() => shiftsRef?.current?.fetch?.()}
          >
            <RestartAltIcon />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => shiftsRef.current?.dataGridRef.current?.printTable()}
          >
            <PrintIcon />
          </IconButton>
          <ShiftItemUpsertButton
            component={Button}
            componentProps={{variant: 'outlined', startIcon: <AddIcon />}}
            onSubmitted={() => shiftsRef?.current?.fetch?.()}
          >
            Add Shift
          </ShiftItemUpsertButton>
          <ShiftImportExportButton
            component={Button}
            componentProps={{
              variant: 'outlined',
              startIcon: <ImportExportIcon />,
            }}
            onSubmitted={() => shiftsRef?.current?.fetch?.()}
          >
            Import/Export
          </ShiftImportExportButton>
        </>
      ),
      ref: shiftsRef,
    },

    {
      label: UseGetLabel({label: 'machine', plural: true}),
      value: 'machine',
      enabled: connect_enabled || proximity_enabled || hazard_ai_enabled,
      component: <AssetMachineList ref={assetMachineRef} />,
      group: 'assets_machines',
      actions: (
        <>
          <IconButton
            color="primary"
            onClick={() => assetMachineRef?.current?.fetch?.()}
          >
            <RestartAltIcon />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() =>
              assetMachineRef.current?.dataGridRef.current?.printTable()
            }
          >
            <PrintIcon />
          </IconButton>
          <AssetMachineItemUpsertButton
            component={Button}
            componentProps={{variant: 'outlined', startIcon: <AddIcon />}}
            onSubmitted={() => assetMachineRef?.current?.fetch?.()}
          >
            {`Add ${UseGetLabel({label: 'machine'})}`}
          </AssetMachineItemUpsertButton>
        </>
      ),
      ref: assetMachineRef,
    },
    {
      label: 'Machine Types',
      value: 'machine-types',
      enabled: proximity_enabled || hazard_ai_enabled || connect_enabled,
      component: <MachineTypeList ref={machineTypesRef} />,
      group: 'assets_machines',
      actions: (
        <>
          <IconButton
            color="primary"
            onClick={() => machineTypesRef?.current?.fetch?.()}
          >
            <RestartAltIcon />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() =>
              machineTypesRef.current?.dataGridRef.current?.printTable()
            }
          >
            <PrintIcon />
          </IconButton>
          <MachineTypeItemUpsertButton
            component={Button}
            componentProps={{variant: 'outlined', startIcon: <AddIcon />}}
            onSubmitted={() => machineTypesRef?.current?.fetch?.()}
          >
            Add Machine Type
          </MachineTypeItemUpsertButton>
          <MachineTypeImportExportButton
            component={Button}
            componentProps={{
              variant: 'outlined',
              startIcon: <ImportExportIcon />,
            }}
            onSubmitted={() => machineTypesRef?.current?.fetch?.()}
          >
            Import/Export
          </MachineTypeImportExportButton>
        </>
      ),
      ref: machineTypesRef,
    },
    {
      label: 'Machine Inputs',
      value: 'machine-inputs',
      enabled: proximity_enabled,
      component: <MachineInputList ref={machineInputsRef} />,
      group: 'assets_machines',
      actions: (
        <>
          <IconButton
            color="primary"
            onClick={() => machineInputsRef?.current?.fetch?.()}
          >
            <RestartAltIcon />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() =>
              machineInputsRef.current?.dataGridRef.current?.printTable()
            }
          >
            <PrintIcon />
          </IconButton>
          <MachineInputItemUpsertButton
            component={Button}
            componentProps={{variant: 'outlined', startIcon: <AddIcon />}}
            onSubmitted={() => machineInputsRef?.current?.fetch?.()}
          >
            Add Machine Input
          </MachineInputItemUpsertButton>
          <MachineInputImportExportButton
            component={Button}
            componentProps={{
              variant: 'outlined',
              startIcon: <ImportExportIcon />,
            }}
            onSubmitted={() => machineInputsRef?.current?.fetch?.()}
          >
            Import/Export
          </MachineInputImportExportButton>
        </>
      ),
      ref: machineInputsRef,
    },
    {
      label: 'Connect Devices',
      value: 'connect_devices',
      enabled: connect_enabled,
      component: <CommtracNodeList ref={commtracNodeRef} />,
      group: 'assets_machines',
      actions: (
        <>
          <IconButton
            color="primary"
            onClick={() => commtracNodeRef?.current?.fetch?.()}
          >
            <RestartAltIcon />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() =>
              commtracNodeRef.current?.dataGridRef.current?.printTable()
            }
          >
            <PrintIcon />
          </IconButton>
        </>
      ),
      ref: commtracNodeRef,
    },
    {
      label: 'Layers',
      value: 'layers',
      enabled: productsEnabled.includes('connect') || connect_enabled,
      group: 'general',
      component: (
        <AccessControl permissions={['get::/layer']}>
          <LayerList ref={layersRef} />
        </AccessControl>
      ),
      actions: (
        <>
          <IconButton
            color="primary"
            onClick={() => layersRef?.current?.fetch?.()}
          >
            <RestartAltIcon />
          </IconButton>
          <AccessControl permissions={['post::/layer']}>
            <LayerItemUpsertButton
              component={Button}
              componentProps={{variant: 'outlined', startIcon: <AddIcon />}}
              onSubmitted={() => layersRef?.current?.fetch?.()}
            >
              Add Layer
            </LayerItemUpsertButton>
          </AccessControl>
        </>
      ),
      ref: layersRef,
    },
    {
      label: 'Maps',
      value: 'maps',
      enabled: productsEnabled.includes('connect') || connect_enabled,
      group: 'general',
      component: (
        <AccessControl permissions={['get::/map']}>
          <MapList ref={mapsRef} />
        </AccessControl>
      ),
      actions: (
        <>
          <IconButton
            color="primary"
            onClick={() => mapsRef?.current?.fetch?.()}
          >
            <RestartAltIcon />
          </IconButton>
          <AccessControl permissions={['post::/map']}>
            <MapItemUploadButton
              component={Button}
              componentProps={{variant: 'outlined', startIcon: <AddIcon />}}
              onSubmitted={() => mapsRef?.current?.fetch?.()}
            >
              Upload Map
            </MapItemUploadButton>
          </AccessControl>
        </>
      ),
      ref: mapsRef,
    },
    {
      label: 'Alarm Groups',
      value: 'alarm_groups',
      enabled: productsEnabled.includes('alarm') || alarm_enabled,
      component: <AlarmGroupList ref={alarmGroups} />,
      group: 'alarms',
      actions: (
        <>
          <IconButton
            color="primary"
            onClick={() => alarmGroups?.current?.fetch?.()}
          >
            <RestartAltIcon />
          </IconButton>
          <AccessControl permissions={['post::/alarm-module-group']}>
            <AlarmGroupItemUpsertButton
              component={Button}
              componentProps={{variant: 'outlined', startIcon: <AddIcon />}}
              onSubmitted={() => alarmGroups?.current?.fetch?.()}
            >
              Add Group
            </AlarmGroupItemUpsertButton>
          </AccessControl>
        </>
      ),
      ref: alarmGroups,
    },
  ] as const;

  const shownTabs = useMemo(() => {
    return tabs.filter((i) => i.enabled && i.group === activeTabGroup);
  }, [
    activeTabGroup,
    connect_enabled,
    proximity_enabled,
    hazard_ai_enabled,
    alarm_enabled,
  ]);

  const location = useLocation();
  const navigate = useNavigate();
  const currentActiveTabInUrl = location.hash?.replaceAll('#', '') || '';
  const isCurrentActiveTabInUrlAvailable = tabs
    ?.filter((it) => !!it?.enabled)
    .find((tab) => tab.value === currentActiveTabInUrl);
  const activeTabValue = isCurrentActiveTabInUrlAvailable
    ? location.hash?.replaceAll('#', '')
    : tabs?.filter((it) => !!it?.enabled)?.[0]?.value;

  const activeTab = tabs.find((i) => i.value === activeTabValue);

  useEffect(() => {
    const currentActiveTabInUrl = location.hash?.replaceAll('#', '') || '';
    const isCurrentActiveTabInUrlAvailable = tabs
      ?.filter((it) => !!it?.enabled)
      .find((tab) => tab.value === currentActiveTabInUrl);
    const activeTabValue = isCurrentActiveTabInUrlAvailable
      ? location.hash?.replaceAll('#', '')
      : tabs?.filter((it) => !!it?.enabled)?.[0]?.value;

    const activeTab = tabs.find((i) => i.value === activeTabValue);
    if (activeTab?.group !== activeTabGroup) {
      setActiveTabGroup(activeTab?.group ?? 'general');
    }
  }, [
    location,
    hazard_ai_enabled,
    connect_enabled,
    proximity_enabled,
    alarm_enabled,
  ]);

  const enabledTabGroups = useMemo(() => {
    return tabGroups.filter((i) => {
      if ((i?.product?.length ?? 0) === 0) {
        return true;
      }
      let results = false;
      if (hazard_ai_enabled) {
        results ||= i?.product?.includes('hazard_ai_enabled') ?? true;
      }
      if (connect_enabled) {
        results ||= i?.product?.includes('connect_enabled') ?? true;
      }
      if (proximity_enabled) {
        results ||= i?.product?.includes('proximity_enabled') ?? true;
      }
      if (alarm_enabled) {
        results ||= i?.product.includes('alarm_enabled') ?? true;
      }
      return results;
    });
  }, [
    tabGroups,
    connect_enabled,
    proximity_enabled,
    hazard_ai_enabled,
    alarm_enabled,
  ]);

  return (
    <DashboardLayout>
      <Box
        display="flex"
        flexDirection="column"
        p={2}
        gap={0.5}
        height="100%"
        overflow="hidden"
      >
        <Box
          display="flex"
          gap={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            id="modal-modal-title"
            component="h1"
            variant="h5"
            sx={{mb: 2}}
          >
            Administration - Company
          </Typography>
          {activeTab?.enabled && (
            <Box
              display="flex"
              gap={1}
              alignItems="center"
              justifyContent="end"
            >
              {activeTab?.actions}
            </Box>
          )}
        </Box>

        <RadioGroup
          sx={{
            my: 2,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            gap: 3,
          }}
          defaultValue="general"
          value={activeTabGroup}
          name="radio-buttons-group"
          onChange={(e) => {
            const groupTabValue = e.target.value;
            console.log(groupTabValue);
            const filterTabs = tabs?.filter((it) => it.group === groupTabValue);
            if (filterTabs?.length > 0) {
              navigate({...location, hash: filterTabs[0].value});
            }
            setActiveTabGroup(e.target.value);
          }}
        >
          {enabledTabGroups.map((it, index: number) => (
            <FormControlLabel
              key={index}
              value={it.name}
              control={<Radio />}
              label={it.label}
            />
          ))}
        </RadioGroup>

        <TabContext value={activeTabValue}>
          <TabList variant="scrollable">
            {shownTabs.map((tab) => (
              <Tab
                key={tab.value}
                label={
                  <Box
                    whiteSpace="nowrap"
                    width="100%"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {tab.label}
                  </Box>
                }
                value={tab.value}
                onClick={() => navigate({...location, hash: tab.value})}
              />
            ))}
          </TabList>

          {shownTabs.map((tab) => (
            <TabPanel
              key={tab.value}
              value={tab.value}
              sx={{
                height: '100%',
                overflow: 'auto',
                p: 0,
              }}
            >
              {tab.component}
            </TabPanel>
          ))}
        </TabContext>
      </Box>
    </DashboardLayout>
  );
};
