/* eslint-disable no-bitwise */
import AddIcon from '@mui/icons-material/Add';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NetworkWifi1BarOutlinedIcon from '@mui/icons-material/NetworkWifi1BarOutlined';
import NetworkWifi2BarOutlinedIcon from '@mui/icons-material/NetworkWifi2BarOutlined';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import TheatersOutlinedIcon from '@mui/icons-material/TheatersOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WifiIcon from '@mui/icons-material/Wifi';
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  IconButton,
  Tooltip,
} from '@mui/material';
import update from 'immutability-helper';
import _, {isNull} from 'lodash';
import {useEffect, useMemo, useRef} from 'react';

import {useConfiguration} from '../../../hooks/configuration';
import {useAppSelector} from '../../../hooks/redux';
import usePrevious from '../../../hooks/usePrevious';
import {ExportField} from '../../../interfaces/Export';
import {
  amsSensorAddressMask,
  GasMonitoringNode,
  GasMonitoringNodeListQuery,
  GasMonitoringNodeListResponse,
} from '../../../interfaces/GasMonitoringNode';
import reduxSelectors from '../../../redux/selectors';
import {
  getAmsBatteryIconColor,
  getAMSBatteryIconType,
  getAmsBatteryPercent,
} from '../../../utils/ams';
import {
  OpenedEntityMode,
  OpenedEntityType,
} from '../../../utils/connect-view-panel';
import {isPresent} from '../../../utils/type-guards';
import AccessControl from '../../common/AccessControl';
import {AMSBatteryIcon} from '../../common/AMSBatteryIcon';
import {AutoRefreshSettingsSelect} from '../../common/AutoRefreshSettingsSelect';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import {Map, MapLatLangCoordinates, MapLayerId} from '../../common/Map';
import {ResizableColumns} from '../../common/ResizableColumns';
import AMSImportExportButton from '../AMSSensor/AMSImportExportButton';
import AMSItemsPurgeButton from '../AMSSensor/AMSItemsPurgeButton';
import AMSSensorItemAcknowledgeButton from '../AMSSensor/AMSSensorItemAcknowledgeButton';
import AMSSensorItemEditButton from '../AMSSensor/AMSSensorItemEditButton';
import AMSSensorItemUpsertButton from '../AMSSensor/AMSSensorItemUpsertButton';
import {AMSSensorToggleNonISDevices} from '../AMSSensor/AMSSensorToggleNonISDevices';

export interface GasMonitoringReportData {
  mapLayers: MapLayerId[];
  mapLevel: number | null;
  params: Partial<GasMonitoringNodeListQuery>;
  selectedIds: string[] | null;
  shownFields: {
    all?: string[];
  };
  grid: {
    pageSize: number;
    page: number;
  };
  exportFields: ExportField[];
}

const DEFAULT_SHOWN_FIELDS = [
  'select',
  'id',
  'edit',
  'device',
  'battery_voltage',
  'disable_event',
  'alert_alarm',
  'no_heartbeat',
  'emod_conf',
  'ava_status',
  'ventilation_status',
  'name',
  'commtrac_external_id',
  'value',
  'unit_id',
  'type_id',
  'date',
  'intrinsically_safe',
  'ava',
  'non_sensing',
  'type_2',
];

export const getGasMonitoringReportData = (): GasMonitoringReportData => ({
  mapLayers: ['street', 'mine', 'alarms', 'ams_sensor_nodes'],
  mapLevel: null,
  params: {
    page: 0,
    limit: 2000,
    status: 'all',
    section_ids: [],
  },
  selectedIds: [],
  shownFields: {
    all: DEFAULT_SHOWN_FIELDS,
  },
  grid: {
    page: 0,
    pageSize: 25,
  },
  exportFields: [],
});

interface Props {
  value: GasMonitoringReportData;
  fetchedData?: GasMonitoringNodeListResponse;
  isLoading?: boolean;
  locationCoordinates?: MapLatLangCoordinates;
  onOpenItem?: (
    id: number,
    entity: OpenedEntityType,
    mode?: OpenedEntityMode
  ) => void;
  onOpenHistory?: (
    id: number | string,
    type:
      | 'asset'
      | 'cn'
      | 'wifi'
      | 'wifiLongTerm'
      | 'employee'
      | 'commtracNodeByCn'
      | 'networkDiagnostics'
      | 'alarm'
      | 'alarm_log'
      | 'hazard_ai_detection_log'
      | 'hazard_ai_heatmap'
      | 'amsShortTerm'
      | 'amsLongTerm'
      | 'amsLocation'
      | 'amsEmoduleInstallationHistory'
      | 'amsEmoduleSensorHistory'
      | 'amsEmoduleCalibration'
  ) => void;
  onChange?: (value?: GasMonitoringReportData) => void;
  onRefresh?: () => void;
  onChangeLocationCoordinates?: (value?: MapLatLangCoordinates) => void;
  onAcknowledgeClose: (id: number) => void;
}

const GasMonitoringReport = ({
  value,
  fetchedData,
  isLoading,
  locationCoordinates,
  onOpenItem,
  onChange,
  onRefresh = () => {},
  onChangeLocationCoordinates,
  onOpenHistory,
  onAcknowledgeClose,
}: Props) => {
  const typeIdLabels = useAppSelector(({assets}) => assets.emoduleTypeIdLabel);
  const unitIdLabels = useAppSelector(({assets}) => assets.emoduleUnitIdLabel);
  const config = useMemo(() => value ?? getGasMonitoringReportData(), [value]);
  const isCompactMode = useAppSelector(reduxSelectors.app.getIsCompactMode);

  /*************/
  /* data grid */
  /*************/
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = useMemo(() => fetchedData?.items ?? [], [fetchedData]);
  const amsNodeLowBattery = useConfiguration('ams', 'ams_node_low_battery');
  const ventilationFailure = useConfiguration('ams', 'ventilation_failure');
  const columns: DataGridColumn<GasMonitoringNode>[] = [
    {
      field: 'select',
      type: 'select',
      hideable: false,
      renderHeader: () => (
        <Checkbox
          color="primary"
          disabled={rows.length === 0}
          checked={selectedItems.length > 0 && selectedAll}
          indeterminate={selectedItems.length > 0 && !selectedAll}
          onChange={() => toggleSelectAllItems()}
        />
      ),
      renderCell: ({row}) => (
        <Checkbox
          color="primary"
          checked={selectedItems.includes(String(row.id))}
          onChange={() => toggleSelectItem(String(row.id))}
        />
      ),
    },
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
      valueGetter: ({row}) => row.id,
    },
    {
      field: 'actions',
      renderHeader: () => (
        <Tooltip title="Edit">
          <MoreHorizIcon />
        </Tooltip>
      ),
      type: 'actions',
      doNotExport: true,
      sxHeader: {textAlign: 'center', p: 0.5},
      sxCell: {textAlign: 'center', p: 0.5},
      renderCell: ({row}) =>
        row.ack === '0' ? (
          <AccessControl permissions={['patch::/ams/:id(\\d+)']}>
            <AMSSensorItemAcknowledgeButton
              item={row}
              pk={row.id}
              locationCoordinates={locationCoordinates}
              component={IconButton}
              onSubmitted={(item) => {
                onRefresh?.();
                onOpenItem?.(item?.id, 'ams', 'ack');
              }}
              onClose={() => {
                onAcknowledgeClose(row.id);
              }}
            >
              <Tooltip title="Acknowledge">
                <WarningAmberIcon color="warning" />
              </Tooltip>
            </AMSSensorItemAcknowledgeButton>
          </AccessControl>
        ) : (
          <AMSSensorItemEditButton
            item={row}
            onOpenItem={onOpenItem}
            onOpenHistory={onOpenHistory}
            component={IconButton}
            componentProps={{
              color: 'primary',
            }}
          >
            <MoreHorizIcon />
          </AMSSensorItemEditButton>
        ),
    },
    {
      field: 'device',
      headerName: 'Device',
      sortable: true,
      renderCell: () => <p>Sensor</p>,
    },
    {
      field: 'battery_voltage',
      headerName: 'Low Battery',
      sxHeader: {minWidth: 60, textAlign: 'center'},
      valueGetter: ({row}) => {
        // Constants
        const externalVoltage = row?.external_voltage;
        const batteryVoltage = row?.battery_voltage;
        const isOnExternalCharger = row?.is_on_external_charger;

        // Return highest values in case no required data
        if (!externalVoltage && !batteryVoltage) {
          return 10;
        }

        // Get a value of used color as a number to be able to sort final result
        const iconColor = getAmsBatteryIconColor({
          batteryVoltage,
          externalVoltage,
          isOnExternalCharger,
          amsNodeLowBatteryValue: amsNodeLowBattery?.value,
          isLowBatteryEvent: row?.e_405,
          isLowExternalChargeEvent: row?.e_409,
        });
        const iconColorNumberValue = iconColor ? 3 : 2;

        // Get a value of used icon type as a number
        const iconType = getAMSBatteryIconType({
          batteryVoltage,
          externalVoltage,
          isOnExternalCharger,
        });
        const iconTypeNumberValues: Record<typeof iconType, number> = {
          power: 1,
          batteryEmpty: 2,
          batteryLow: 3,
          batteryMid: 4,
          batteryHigh: 5,
          batteryFull: 6,
        };
        const currentIconTypeNumberValue = iconTypeNumberValues[iconType];

        return iconColorNumberValue + currentIconTypeNumberValue;
      },
      renderHeader: () => (
        <Tooltip title="Low Battery">
          <BatteryChargingFullIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      renderCell: ({row}) => {
        const externalVoltage = row?.external_voltage;
        const batteryVoltage = row?.battery_voltage;
        const isOnExternalCharger = row?.is_on_external_charger;

        if (!externalVoltage && !batteryVoltage) {
          return (
            <Tooltip title="Unknown">
              <Box display="flex" justifyContent="center">
                <p>?</p>
              </Box>
            </Tooltip>
          );
        }

        const tooltipContent =
          !isOnExternalCharger || !externalVoltage || externalVoltage < 2
            ? getAmsBatteryPercent(batteryVoltage)
            : `${externalVoltage} v`;
        const iconColor = getAmsBatteryIconColor({
          batteryVoltage,
          externalVoltage,
          isOnExternalCharger,
          amsNodeLowBatteryValue: amsNodeLowBattery?.value,
          isLowBatteryEvent: row?.e_405,
          isLowExternalChargeEvent: row?.e_409,
        });
        const iconType = getAMSBatteryIconType({
          batteryVoltage,
          externalVoltage,
          isOnExternalCharger,
        });
        const iconColorProp: {color?: 'error'} = iconColor
          ? {color: iconColor}
          : {};

        return (
          <Tooltip title={tooltipContent}>
            {iconType === 'power' ? (
              <PowerSettingsNewOutlinedIcon {...iconColorProp} />
            ) : (
              <div>
                <AMSBatteryIcon iconType={iconType} {...iconColorProp} />
              </div>
            )}
          </Tooltip>
        );
      },
    },
    {
      field: 'disable_event',
      headerName: 'Events',
      sxHeader: {minWidth: 60},
      valueGetter: ({row}) => row.disable_event,
      renderHeader: () => (
        <Tooltip title="Events">
          <DoDisturbIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      renderCell: ({row}) => {
        if (row.disable_event) {
          return (
            <Tooltip title="Events are Disabled">
              <DoDisturbIcon />
            </Tooltip>
          );
        }

        return null;
      },
    },
    {
      field: 'alert_alarm',
      headerName: 'Alert/Alarm',
      sxHeader: {minWidth: 60},
      valueGetter: ({row}) => {
        let value = 0;

        if (row.e_402 || row.e_452 || row.e_462 || row.e_474 || row.e_484) {
          value += 2;
        }

        if (row.e_403 || row.e_453 || row.e_463 || row.e_475 || row.e_483) {
          value++;
        }

        return value;
      },
      renderHeader: () => (
        <Tooltip title="Alert/Alarm">
          <WarningIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      renderCell: ({row}) => {
        // Values accumulated since several events can occur at same time
        if (
          (row.e_402 || row.e_452 || row.e_462 || row.e_474 || row.e_484) &&
          row.disable_event === 0
        ) {
          return (
            <Tooltip title="Alarm">
              <WarningIcon color="error" />
            </Tooltip>
          );
        }

        if (
          (row.e_403 || row.e_453 || row.e_463 || row.e_475 || row.e_483) &&
          row.disable_event === 0
        ) {
          return (
            <Tooltip title="Alert">
              <WarningIcon color="warning" />
            </Tooltip>
          );
        }

        return null;
      },
    },
    {
      field: 'no_heartbeat',
      headerName: 'Heartbeat',
      sxHeader: {minWidth: 60},
      valueGetter: ({row}) => {
        // Values accumulated since several events can occur at same time
        let value = 0;

        if (row.e_407) {
          value += 4;
        }

        if (row.e_404) {
          value += 2;
        }

        if (row.e_406) {
          value++;
        }

        return value;
      },
      renderHeader: () => (
        <Tooltip title="Heartbeat">
          <WifiIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      renderCell: ({row}) => {
        // ATEST values are not taken into account in current 5.3 version
        if (row.e_407) {
          return (
            <Tooltip title="AMS Sensors many ENV messages">
              <ForumOutlinedIcon />
            </Tooltip>
          );
        }

        if (row.e_404) {
          return (
            <Tooltip title="AMS Sensors no ENV messages">
              <NetworkWifi1BarOutlinedIcon color="error" />
            </Tooltip>
          );
        }

        if (row.e_406) {
          return (
            <Tooltip title="AMS Sensors few ENV messages">
              <NetworkWifi2BarOutlinedIcon color="warning" />
            </Tooltip>
          );
        }

        return '';
      },
    },
    {
      field: 'emod_conf',
      headerName: 'EMod Conf',
      sxHeader: {minWidth: 60},
      renderHeader: () => (
        <Tooltip title="EMod Conf">
          <TimerOutlinedIcon />
        </Tooltip>
      ),
      valueGetter: ({row}) => {
        if (row.e_408 && row.e_410) {
          return 3;
        }

        if (row.e_410) {
          return 2;
        }

        if (row.e_408) {
          return 1;
        }
      },
      sortable: true,
      hideable: false,
      renderCell: ({row}) => {
        if (row.e_410) {
          return (
            <Tooltip title="e-Module Unregistered">
              <TheatersOutlinedIcon />
            </Tooltip>
          );
        }

        if (row.e_408) {
          return (
            <Tooltip title="e-Module Calibration Date Expired">
              <TimerOutlinedIcon color="error" />
            </Tooltip>
          );
        }

        return null;
      },
    },
    {
      field: 'ava_status',
      headerName: 'AVA Status',
      sxHeader: {minWidth: 60},
      valueGetter: ({row}) => {
        if (row.ava) {
          const avaStatusAck = row?.ava_status_ack;
          const avaStatus = row?.ava_status;

          const avaIconNumberValue = avaStatus === avaStatusAck ? 20 : 10;

          if (avaStatus === 402) {
            return avaIconNumberValue + 3;
          }

          if (avaStatus === 403) {
            return avaIconNumberValue + 2;
          }

          return avaIconNumberValue + 1;
        }

        return 0;
      },
      renderHeader: () => (
        <Tooltip title="AVA Status">
          <FiberManualRecordIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      renderCell: ({row}) => {
        if (row.ava) {
          const avaStatusAck = row?.ava_status_ack;
          const avaStatus = row?.ava_status;

          const AvaIcon =
            avaStatus === avaStatusAck
              ? FiberManualRecordIcon
              : FiberManualRecordOutlinedIcon;

          if (avaStatus === 402) {
            return (
              <Tooltip title="Alarm">
                <AvaIcon color="error" />
              </Tooltip>
            );
          }

          if (avaStatus === 403) {
            return (
              <Tooltip title="Alert">
                <AvaIcon color="warning" />
              </Tooltip>
            );
          }

          return (
            <Tooltip title="Ok">
              <AvaIcon color="success" />
            </Tooltip>
          );
        }

        return '';
      },
    },
    {
      field: 'ventilation_status',
      headerName: 'Ventilation Status',
      sxHeader: {minWidth: 60},
      valueGetter: ({row}) => {
        if (
          ventilationFailure?.value &&
          !row?.intrinsically_safe &&
          row?.ventilation_status
        ) {
          return row?.ventilation_status_ack ? 2 : 1;
        }

        return 0;
      },
      renderHeader: () => (
        <Tooltip title="Ventilation Status">
          <CropSquareIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      renderCell: ({row}) => {
        if (
          ventilationFailure?.value &&
          !row?.intrinsically_safe &&
          row?.ventilation_status
        ) {
          return row?.ventilation_status_ack ? (
            <Tooltip title="ACK">
              <SquareRoundedIcon color="error" />
            </Tooltip>
          ) : (
            <Tooltip title="SENT">
              <CropSquareIcon color="error" />
            </Tooltip>
          );
        }

        return '';
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
      valueGetter: ({row}) => row.name,
    },
    {
      field: 'commtrac_external_id',
      headerName: 'Network ID',
      sortable: true,
      valueGetter: ({row}) => row?.commtrac_external_id & amsSensorAddressMask,
    },
    {
      field: 'value',
      headerName: 'Value',
      sxCell: {textAlign: 'center'},
      sortable: true,
      valueGetter: ({row}) => row.value,
    },
    {
      field: 'unit_id',
      headerName: 'Units',
      sxCell: {textAlign: 'center'},
      sortable: true,
      valueGetter: ({row}) =>
        row && !isNull(row.unit_id) ? unitIdLabels?.[row.unit_id] : '',
    },
    {
      field: 'type_id',
      headerName: 'Type',
      sxCell: {textAlign: 'left'},
      sortable: true,
      valueGetter: ({row}) => {
        if (_.isNull(row.type_id)) {
          return 'None';
        } else {
          return typeIdLabels?.[row.type_id];
        }
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      sortable: true,
      valueGetter: ({row}) => row.date,
    },
    {
      field: 'intrinsically_safe',
      headerName: 'IS',
      sortable: true,
      valueGetter: ({row}) => (row.intrinsically_safe === 1 ? 'Yes' : 'No'),
    },
    {
      field: 'ava',
      headerName: 'AVA',
      sxCell: {textAlign: 'center'},
      sortable: true,
      valueGetter: ({row}) => row.ava,
      renderCell: ({row}) => {
        if (row?.ava === 0) {
          return 'No';
        }

        if (row?.ava === 1) {
          return 'Yes';
        }

        return '';
      },
    },
    {
      field: 'non_sensing',
      headerName: 'Non-Sensing',
      sxCell: {textAlign: 'center'},
      sortable: true,
      valueGetter: ({row}) => row.non_sensing,
      renderCell: ({row}) => {
        if (row?.non_sensing === 0) {
          return 'No';
        }

        if (row?.non_sensing === 1) {
          return 'Yes';
        }

        return '';
      },
    },
    {
      field: 'type_2',
      headerName: 'Type 2',
      sortable: true,
      valueGetter: ({row}) => row.type_2,
    },
    {
      field: 'sensor_serial_number',
      headerName: 'Sensor SN',
      sxCell: {textAlign: 'center'},
      sortable: true,
      valueGetter: ({row}) => row?.sensor_serial_number ?? '',
    },
    {
      field: 'external_voltage',
      headerName: 'Ext Voltage',
      sxCell: {textAlign: 'center'},
      sortable: true,
      valueGetter: ({row}) => row?.external_voltage ?? '',
    },
    {
      field: 'pos_lat',
      headerName: 'Lat',
      sortable: true,
      valueGetter: ({row}) => row?.pos_lat ?? '',
    },
    {
      field: 'pos_lon',
      headerName: 'Lon',
      sortable: true,
      valueGetter: ({row}) => row?.pos_lon ?? '',
    },
    {
      field: 'power_source',
      headerName: 'Power Source',
      sxCell: {textAlign: 'center'},
      sortable: true,
      valueGetter: ({row}) => row?.power_source ?? '',
    },
    {
      field: 'callibration_date',
      headerName: 'Calibaration Date',
      sortable: true,
      valueGetter: ({row}) => row?.callibration_date ?? '',
    },
    {
      field: 'module_absent',
      headerName: 'Module Absent',
      sxCell: {textAlign: 'center'},
      sortable: true,
      valueGetter: ({row}) => row?.module_absent ?? '',
    },
    {
      field: 'module_warning',
      headerName: 'Module Warning',
      sxCell: {textAlign: 'center'},
      sortable: true,
      valueGetter: ({row}) => row?.module_warning ?? '',
    },
    {
      field: 'point1_tripped',
      headerName: 'Point1 Tripped',
      sxCell: {textAlign: 'center'},
      sortable: true,
      valueGetter: ({row}) => row?.point1_tripped ?? '',
    },
    {
      field: 'point2_tripped',
      headerName: 'Point2 Tripped',
      sxCell: {textAlign: 'center'},
      sortable: true,
      valueGetter: ({row}) => row?.point2_tripped ?? '',
    },
    {
      field: 'stel_alarm',
      headerName: 'Stel Alarm',
      sortable: true,
      valueGetter: ({row}) => row?.stel_alarm ?? '',
    },
    {
      field: 'twa_alarm',
      headerName: 'Twa Alarm',
      sortable: true,
      valueGetter: ({row}) => row?.twa_alarm ?? '',
    },
    {
      field: 'fault',
      headerName: 'Fault',
      sortable: true,
      valueGetter: ({row}) => row?.fault ?? '',
    },
    {
      field: 'pellister_over',
      headerName: 'Pellister Over',
      sortable: true,
      valueGetter: ({row}) => row?.pellister_over ?? '',
    },
    {
      field: 'input1',
      headerName: 'Input1',
      sortable: true,
      valueGetter: ({row}) => row?.input1 ?? '',
    },
    {
      field: 'input2',
      headerName: 'Input2',
      sortable: true,
      valueGetter: ({row}) => row?.input2 ?? '',
    },
    {
      field: 'output1',
      headerName: 'Output1',
      sortable: true,
      valueGetter: ({row}) => row?.output1 ?? '',
    },
    {
      field: 'output2',
      headerName: 'Output2',
      sortable: true,
      valueGetter: ({row}) => row?.output2 ?? '',
    },
  ];

  const shownFields = useMemo(() => {
    return config.shownFields.all;
  }, [config]);

  // Use effect here
  useEffect(() => {
    const excludedFields = [
      'actions',
      'ava',
      'device',
      'intrinsically_safe',
      'module_warning',
      'non_sensing',
      'power_source',
    ]; // ? Listed fields are not supported by BE.
    // console.log(
    //   columns
    //     .filter(
    //       (col) => !!col.headerName && !excludedFields.includes(col.field)
    //     )
    //     .map((col) => ({
    //       field: col.field,
    //       label: col.headerName,
    //       hidden: !shownFields?.includes(col.field),
    //     }))
    // );
    onChange?.(
      update(config, {
        exportFields: {
          $set: columns
            .filter(
              (col) => !!col.headerName && !excludedFields.includes(col.field)
            )
            .map((col) => ({
              field: col.field,
              label: col.headerName,
              hidden: !shownFields?.includes(col.field),
            })),
        },
      })
    );
  }, [shownFields]);

  const handleChangeShownFields = (fields: string[]) => {
    onChange?.(
      update(config, {
        shownFields: {
          all: {$set: fields},
        },
      })
    );
  };

  /*******************/
  /* multiple select */
  /*******************/
  const selectedItems = config.selectedIds ?? [];

  const selectedRows = useMemo(
    () => rows.filter((i) => config.selectedIds?.includes(String(i.id))),
    [rows, config.selectedIds]
  );

  const selectedAll = useMemo(
    () => rows.length === selectedRows.length,
    [rows, selectedRows]
  );

  const toggleSelectItem = (id: string) => {
    if (config.selectedIds?.includes(id)) {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: config.selectedIds.filter((i) => i !== id),
          },
        })
      );
    } else {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: [...(config.selectedIds ?? []), id],
          },
        })
      );
    }
  };

  const selectAll = () => {
    onChange?.(
      update(config, {
        selectedIds: {
          $set: rows?.map((item) => String(item.id)) ?? [],
        },
      })
    );
  };

  const unselectAll = () => {
    onChange?.(
      update(config, {
        selectedIds: {
          $set: [],
        },
      })
    );
  };

  const toggleSelectAllItems = () => {
    if (selectedItems.length >= rows.length) {
      unselectAll();
    } else {
      selectAll();
    }
  };

  useEffect(() => {
    if (
      config.selectedIds &&
      config.selectedIds.length !== selectedRows.length
    ) {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: selectedRows.map((item) => String(item.id)),
          },
        })
      );
    }
  }, [config.selectedIds, selectedRows]);

  const prevSelectedAll = usePrevious(selectedAll);

  useEffect(() => {
    if (prevSelectedAll && !selectedAll) {
      selectAll();
    }
  }, [rows]);

  const mapData = useMemo(() => {
    if (fetchedData?.items && config.selectedIds?.length) {
      return fetchedData?.items.filter((i) =>
        config.selectedIds?.includes(`${i.id}`)
      );
    }
    return [];
  }, [fetchedData, config.selectedIds, selectedItems]);

  // Refresh Interval
  const refreshPeriod = useConfiguration(
    'auto-refresh',
    'ams_grid_autorefresh_rate'
  );

  return (
    <Box height="100%" width="100%">
      <ResizableColumns
        left={
          <>
            <Box
              display="flex"
              alignItems="center"
              height={isCompactMode ? 56 : 70}
              p={isCompactMode ? 1 : 2}
              minWidth={520}
              gap={2}
            >
              <AMSSensorToggleNonISDevices
                onDone={() => onRefresh?.()}
                refreshPeriod={refreshPeriod}
              />
            </Box>
            <Box height="100%">
              <Map
                panel="ams_sensor_nodes_report"
                amsSensorNodes={mapData}
                selectedMapLayers={config.mapLayers}
                selectedLevel={config.mapLevel}
                height="calc(100% - 70px)"
                minWidth={200}
                availableMapLayers={[
                  'street',
                  'mine',
                  'alarms',
                  'ams_sensor_nodes',
                ]}
                onSelectMapLayers={(v) => {
                  onChange?.(
                    update(config, {
                      mapLayers: {
                        $set: v,
                      },
                    })
                  );
                }}
                onSelectLevel={(v) => {
                  onChange?.(
                    update(config, {
                      mapLevel: {
                        $set: v,
                      },
                    })
                  );
                }}
                onOpenItem={(e) => {
                  const newSenor =
                    mapData.filter((it) => it.ack === '0' && it.id === e)
                      ?.length === 1;
                  onOpenItem?.(e, 'ams', newSenor ? 'ack' : 'view');
                }}
                onOpenHistory={onOpenHistory}
                onGetClickCoordinates={onChangeLocationCoordinates}
              />
            </Box>
          </>
        }
      >
        <Box display="flex" flexDirection="column" height="100%">
          <Box p={isCompactMode ? 1 : 2} minWidth={800}>
            <Box
              display="flex"
              flexDirection="row-reverse"
              justifyContent="space-between"
              alignItems="center"
              height={40}
              gap={2}
            >
              <Box display="flex" height="100%">
                <ButtonGroup>
                  <AccessControl permissions={['post::/ams']}>
                    <AMSSensorItemUpsertButton
                      onSubmitted={() => {
                        onRefresh?.();
                        onChangeLocationCoordinates?.(undefined);
                      }}
                      locationCoordinates={locationCoordinates}
                      componentProps={{
                        color: 'primary',
                        size: 'small',
                        startIcon: <AddIcon />,
                      }}
                    >
                      Sensor
                    </AMSSensorItemUpsertButton>
                  </AccessControl>

                  <AMSImportExportButton
                    value={value}
                    component={Button}
                    componentProps={{color: 'primary'}}
                  >
                    <ImportExportIcon />
                  </AMSImportExportButton>

                  <Button onClick={() => onRefresh?.()}>
                    <RefreshIcon />
                  </Button>

                  {refreshPeriod ? (
                    <AutoRefreshSettingsSelect refreshPeriod={refreshPeriod} />
                  ) : null}
                  <Button onClick={() => {}}>
                    <PrintIcon
                      onClick={() => dataGridRef.current?.printTable()}
                    />
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>
          </Box>
          <DataGrid
            ref={dataGridRef}
            rows={rows}
            columns={columns}
            size="small"
            pagination
            page={config.grid.page}
            pageSize={config.grid.pageSize}
            loading={isLoading}
            shownFields={shownFields}
            sxFooter={{
              bgcolor: (theme) =>
                theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
            }}
            footerStart={
              selectedItems.length ? (
                <Box display="flex" alignItems="center" gap={3}>
                  <Box
                    display="flex"
                    gap={0.5}
                    alignItems="center"
                    height="100%"
                    whiteSpace="nowrap"
                  >
                    {selectedItems.length} selected
                  </Box>
                  <AccessControl permissions={['post::/purge']}>
                    <Box display="flex" height={40}>
                      <AMSItemsPurgeButton
                        amsIds={selectedRows
                          .filter((i) => i.id)
                          .map((i) => i.id)
                          .filter(isPresent)}
                        componentProps={{size: 'small', variant: 'outlined'}}
                        onDone={() => onRefresh?.()}
                      >
                        <RemoveCircleOutlineIcon
                          fontSize="small"
                          sx={{mr: 1}}
                        />
                        Purge
                      </AMSItemsPurgeButton>
                    </Box>
                  </AccessControl>
                </Box>
              ) : null
            }
            onShownFieldsChange={handleChangeShownFields}
            onPageChange={(v) => {
              onChange?.(
                update(config, {
                  grid: {
                    page: {
                      $set: v,
                    },
                  },
                })
              );
            }}
            onPageSizeChange={(v) => {
              onChange?.(
                update(config, {
                  grid: {
                    pageSize: {
                      $set: v,
                    },
                  },
                })
              );
            }}
          />
        </Box>
      </ResizableColumns>
    </Box>
  );
};

export default GasMonitoringReport;
