import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {Button, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import {AssetMachine} from '../../interfaces/AssetMachine';
import AccessControl from '../common/AccessControl';
import CommtracNodeItemAssetCheckInOutModal from '../commtrac-nodes/CommtracNodeItemAssetCheckInOutModal';
import AssetMachineItemActivationModal from './AssetMachineItemActivationModal';
import AssetMachineItemPurgeModal from './AssetMachineItemPurgeModal';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  item: AssetMachine;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onSubmitted?: () => void;
  onPurged?: () => void;
}

const PurgeActionTypes = {
  purgeDevice: 'device',
  purgeData: 'data',
} as const;

type Action = 'activation' | 'purgeDevice' | 'purgeData' | 'checkInOut';

const AssetMachineItemActionsButton = <
  T extends ComponentType = typeof Button,
>({
  item,
  component,
  componentProps,
  children,
  onSubmitted,
  onPurged,
}: Props<T>) => {
  const Component = component ?? Button;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [action, setAction] = useState<Action>();
  const isPurgeAction = action === 'purgeDevice' || action === 'purgeData';
  const purgeType = isPurgeAction ? PurgeActionTypes[action] : null;

  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onBackdropClick={() => setAnchorEl(null)}
      >
        {item.commtrac_node_id ? (
          item.checked_in ? (
            <AccessControl
              permissions={['patch::/commtrac-node/:id/miner/force_check_out']}
            >
              <MenuItem
                onClick={() => {
                  setAction('checkInOut');
                  setAnchorEl(null);
                }}
              >
                <RadioButtonUncheckedIcon fontSize="small" sx={{mr: 1.5}} />
                Force Check Out
              </MenuItem>
            </AccessControl>
          ) : (
            <AccessControl
              permissions={['patch::/commtrac-node/:id/miner/force_check_in']}
            >
              <MenuItem
                onClick={() => {
                  setAction('checkInOut');
                  setAnchorEl(null);
                }}
              >
                <RadioButtonCheckedIcon fontSize="small" sx={{mr: 1.5}} />
                Force Check In
              </MenuItem>
            </AccessControl>
          )
        ) : null}

        <AccessControl permissions={['patch::/asset-machine/:id']}>
          <MenuItem
            onClick={() => {
              setAction('activation');
              setAnchorEl(null);
            }}
          >
            {item.status === 'active' ? (
              <>
                <CloseIcon fontSize="small" sx={{mr: 1.5}} />
                DeActivate
              </>
            ) : (
              <>
                <CheckIcon fontSize="small" sx={{mr: 1.5}} />
                Activate
              </>
            )}
          </MenuItem>
        </AccessControl>

        {item.commtrac_node_id ? (
          <AccessControl permissions={['post::/purge']}>
            <MenuItem divider />
            <MenuItem disabled>
              Purge
            </MenuItem>
            <MenuItem divider sx={{ padding: '0px' }}/>
            <MenuItem
              onClick={() => {
                setAction('purgeDevice');
                setAnchorEl(null);
              }}
            >
              <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 1.5}} />
              All
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAction('purgeData');
                setAnchorEl(null);
              }}
            >
              <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 1.5}} />
              Transactions
            </MenuItem>
          </AccessControl>
        ) : null}
      </Menu>

      {action === 'activation' ? (
        <AssetMachineItemActivationModal
          item={item}
          open={action === 'activation'}
          onClose={() => setAction(undefined)}
          onSubmitted={onSubmitted}
        />
      ) : action === 'checkInOut' && item.commtrac_node_id ? (
        <CommtracNodeItemAssetCheckInOutModal
          pk={item.commtrac_node_id}
          action={item.checked_in ? 'checkOut' : 'checkIn'}
          open={action === 'checkInOut'}
          onClose={() => setAction(undefined)}
          onSubmitted={onSubmitted}
        />
      ) : purgeType ? (
        <AssetMachineItemPurgeModal
          item={item}
          action={purgeType}
          open={!!purgeType}
          onClose={() => setAction(undefined)}
          onSubmitted={onPurged}
        />
      ) : null}
    </>
  );
};

export default AssetMachineItemActionsButton;
